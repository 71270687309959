import React from "react";
import Footer from "../../components/Footer";
import { Box } from "@mui/material";
import PricingCommon from "./components/PricingCommon";

const Pricing = () => {
  return (
    <Box sx={{ overflowY: "scroll", overflowX: "hidden" }} height="100vh">
        <PricingCommon />
        <Footer />
    </Box>
  );
};

export default Pricing;
