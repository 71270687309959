import React from "react";
import {
  Box,
  Card,
  Divider,
  TextField,
  Typography,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import Onboarding from "../components/Onboarding";
import SignUpTextField from "../components/SignUpTextField";

const IndustryWorkBackground = () => {
  const list = [
    "Company Name",
    "Designation",
    "LinkedIn ID",
    "Area of Work",
    "Investigational Techniques",
    "Location",
  ];
  const CreateWorkBgCard = () => {
    return (
      <Card
        sx={{
          marginTop: "20px",
          maxWidth: 450,
          padding: "50px",
          borderRadius: "28px",
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "48px",
              textAlign: "center",
            }}
          >
            Create Account
          </Typography>
          <Typography
            sx={{
              color: "#3CA465",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "23px",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Let's get you started
          </Typography>
          <Box display="flex" flexDirection="column" width="100%" gap={2}>
            {list.map((item) => (
              <SignUpTextField
                key={item}
                id={item}
                placeholder={item}
                fullWidth={false}
              />
            ))}
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="flex-end"
              marginTop="20px"
            >
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#3FA268",
                  color: "white",
                  padding: "12px 60px 12px 60px",
                  textTransform: "none",
                  fontSize: "16px",
                  fontFamily: "Poppins",
                  fontWeight: "700",
                  borderRadius: "10px   ",
                  "&:hover": {
                    backgroundColor: "#3FA268",
                  },
                }}
              >
                Next
              </Button>

              <Typography
                sx={{
                  marginTop: "5px",
                  color: "#3CA465",
                  fontFamily: "Poppins",
                  fontSize: "0.7rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%",
                  marginRight: "4px",
                }}
              >
                Having troubles?
                <span style={{ color: "#FFF" }}>&nbsp;&nbsp;Get Help</span>
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };
  return <Onboarding Component={CreateWorkBgCard} />;
};

export default IndustryWorkBackground;
