import Anubhav from "../assets/profilePics/Anubhav.png";
import Jivant from "../assets/profilePics/jivant.png";
import Kalyan from "../assets/profilePics/kalyan.png";
import Mandip from "../assets/profilePics/mandip.png";
import Nishant from "../assets/profilePics/Nishant.png";
import Prerak from "../assets/profilePics/prerak.png";
import Rajdeep from "../assets/profilePics/rajdeep.png";
import Rishav from "../assets/profilePics/rishav.png";
import Shashank from "../assets/profilePics/shashank.png";
import Shivanshu from "../assets/profilePics/shivanshu.png";

export const ProfilePics = [
  {
    name: "Shashank",
    image: Shashank,
    position: "Found & CEO",
    college: "IIT Delhi",

    // linkedin: "https://www.linkedin.com/in/anubhav-singh-1b1b3b1b0/",
    // twitter: "https://twitter.com/AnubhavSingh_0",
    // instagram: "https://www.instagram.com/anubhav_singh_0/",
  },
  {
    name: "Kalyan",
    image: Kalyan,
    position: "CTO",
    college: "KIIT Bhubaneswar",
  },
  {
    name: "Jivant",
    image: Jivant,
    position: "Parterships Head",
    college: "IIT Delhi",
  },
  {
    name: "Anubhav",
    image: Anubhav,
    position: "Frontend Head",
    college: "IIT Roorke",
  },
  {
    name: "Nishant",
    image: Nishant,
    position: "Data & ML",
    college: "IIT Delhi",
  },
  {
    name: "Prerak",
    image: Prerak,
    position: "ML Head",
    college: "KIIT Bhubaneswar",
  },
  {
    name: "Rajdeep",
    image: Rajdeep,
    position: "Cloud",
    college: "KIIT Bhubaneswar",
  },
  {
    name: "Rishav",
    image: Rishav,
    position: "Frontend Developer",
    college: "IIT Roorkee",
  },
  {
    name: "Mandip",
    image: Mandip,
    position: "Marketing Automation",
    college: "Dropout",
  },
  {
    name: "Shivanshu",
    image: Shivanshu,
    position: "UI/UX",
    college: "IIT Roorkee",
  },
];
