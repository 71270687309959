import React from "react";
import BackgroundScreen from "../components/BackgroundScreen";
import { Box, Card } from "@mui/material";
import ProgressBar from "../components/ProgressBar";
import { StepList } from "../resources/StepList";
import LargeTextSmallText from "../components/LargeTextSmallText";
import AddMore from "../components/AddMore";
import BackContinueButtons from "../components/BackContinueButtons";
import { useNavigate } from "react-router-dom";
import RNDTextField from "../components/RNDTextField";

const RNDReq = () => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/create-new-rnd/goals");
  };
  const handleBack = () => {
    navigate("/create-new-rnd/background");
  };
  const ComponentBg = () => {
    return (
      <Box
        minWidth="65vw"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -63%)",
            md: "translate(-50%, -50%)",
          },
          textAlign: "center",
        }}
      >
        <Box height="50px"></Box>
        <ProgressBar stepList={StepList} progress={45} stepIndex={1} />
        <Card
          sx={{
            marginTop: "20px",
            maxHeight: "60vh",
            maxWidth: "100%",
            padding: "50px",
            borderRadius: "15px",
            background: "rgba(255, 255, 255, 0.04)",
            textAlign: "left",
            overflowY: "scroll",
          }}
        >
          <Box width="100%">
            <LargeTextSmallText
              mainText={"What are the essential prerequisites?"}
              required={true}
            />
            <RNDTextField placeholder={""} rows={3} maxRows={3} />
            <AddMore text={"Add another essential requirement"} />
            <Box height="55px"></Box>

            <LargeTextSmallText
              mainText={"What are the nice to have prerequisites?"}
              required={false}
            />
            <AddMore text={"Add desirable requirement"} />
            <Box height="55px"></Box>

            <LargeTextSmallText
              mainText={"Prerequisites not in the scope?"}
              required={false}
            />
            <AddMore text={"Add out of scope prerequisites"} />
            <Box height="72px"></Box>

            <BackContinueButtons
              backFun={handleBack}
              continueFun={handleContinue}
            />
          </Box>
        </Card>
      </Box>
    );
  };

  return <BackgroundScreen Component={ComponentBg} Title={"Requirements"} />;
};

export default RNDReq;
