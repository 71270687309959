import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function bucketUploader(data) {
  var { fileInput, tag} = data;
  console.log("File input: ", fileInput);
  const formData = new FormData();
  formData.append('tag',tag);
  formData.append('file', fileInput);
  console.log("Form data: ", formData.get("file"));
  try {
    const response = await axios({
      method: "post",
      url: `${baseURL}/researcher/upload_image`,
      data: formData,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("researcherToken")}`,
        "Content-Type": "multipart/form-data",
      },
    });

    const imageUrl = response.data.imageUrl;
    console.log("File uploaded successfully:", imageUrl);
    return imageUrl;

    // Use imageUrl as needed, e.g., update state or schema
  } catch (error) {
    throw new Error(error.message);
  }
}

export default bucketUploader;
