import React from "react";
import {
  Box,
  Typography,
  Grid,
  Card,
  Divider,
  IconButton,
} from "@mui/material";
import AboutHero from "./components/AboutHero";
import FeaturedServices from "../Landing_Page/components/FeaturedServices";
import AboutCommon from "./components/AboutCommon";
import Footer from "../../components/Footer";
import AboutBottomIcons from "./components/AboutBottomIcons";
import BottomIcons from "../Landing_Page/components/BottomIcons";

const About = () => {
  return (
    <Box
      sx={{
        overflowY: "scroll",
        overflowX: "hidden",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      height={"100vh"}
    >
      <AboutHero />
      {/* <AboutBottomIcons /> */}
      <AboutCommon />
      <Footer />
    </Box>
  );
};

export default About;
