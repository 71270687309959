import React from "react";
import { Box, Card, Typography } from "@mui/material";
import bg from "../../../assets/Background_pattern_decorative.png";
import bg_mb from "../../../assets/Background_pattern_decorative_mb.png";
import "../../Login_Page/Login.css";
import researcherBG from "../../../assets/researcher_bg.png";

const BackgroundComp = ({ Component }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // justifyContent: "center",
        padding: "4rem",
        flexGrow: 1,
        // bgcolor: "red",
        backgroundImage: `url(${researcherBG})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        height: "85vh",
        overflowY: "scroll",
        paddingBottom: "100px",
      }}
    >
      {/* <img src={bg} alt="Background" className="bg-desktop" />
      <img src={bg_mb} alt="Background Mobile" className="bg-mobile" /> */}
      <Component />
    </Box>
  );
};

export default BackgroundComp;
