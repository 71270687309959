import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function forgotPasswordHelper(data) {
  console.log("Data for forgot password is:", data);
  try {
    const endpoint = `${baseURL}/mail/send_mail`;
    const response = await axios.post(endpoint, data);
    console.log(response.data);
    // localStorage.setItem("researcherToken", response.data.token);
    return response.data;
  } catch (error) {
    console.log("Error in forgotPasswordHelper", error.response.data.error);
    throw new Error(error.response.data.error);
  }
}

export default forgotPasswordHelper;
