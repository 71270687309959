import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function SubmitContactForm(data) {
  try {
    const endpoint = `${baseURL}/contact/send`;
    const response = await axios.post(endpoint, data);
    console.log("Entering try block of SubmitContactForm");
    return "Request sent successfully!";
  } catch (error) {
    console.log("Entering catch block of SubmitContactForm");
    if (error.response && error.response.status === 403) {
      console.error("Forbidden error:", error.response.data);
      return "Invalid email. Please use your organization email.";
    } else {
      console.error("Error sending the request:", error.response.data.message);
      return "An error occurred while sending the request.";
    }
  }
}

export default SubmitContactForm;
