import React from "react";
import { TextField } from "@mui/material";

const RNDTextField = ({placeholder, rows, maxRows}) => {
  return (
    <TextField
      variant="standard"
      multiline
      placeholder={placeholder}
      rows={rows}
      maxRows={maxRows}
      InputProps={{
        disableUnderline: true,
        style: { color: "#FFF", fontSize: "18px" },
      }}
      sx={{
        marginTop: "10px",
        flexGrow: 1,
        borderRadius: "10px",
        border: "1px solid rgba(255, 255, 255, 0.25)",
        color: "#FFF",
        padding: "5px 10px",
        width: { xs: "100%", md: "70%" },
      }}
    />
  );
};

export default RNDTextField;
