import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import logo from "../../../assets/LogoMain.png";
import avatar from "../../../assets/Avatar.png";
import setting from "../../../assets/settings_1.png";
import notification from "../../../assets/bell-01.png";

function Navbar({ profileImage }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingX: { xs: "20px", md: "80px" },
        paddingY: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <img src={logo} alt="logo" />

        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Home
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Dashboard
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Projects
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Tasks
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Reporting
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              cursor: "pointer  ",
            }}
          >
            Users
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <img src={setting} alt="" />
        </button>
        <button
          style={{
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <img src={notification} alt="" />
        </button>
        <img
          src={profileImage || avatar}
          alt="profile image"
          style={{
            borderRadius: "50%",
            width: "40px",
            height: "40px",
          }}
        />
      </Box>
    </Box>
  );
}

export default Navbar;
