import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import globe from "../../../assets/icon_images/Group_34.png";
import BottomIcons from "./BottomIcons";
import Navbar from "../../../components/Navbar";
import xs_globe from "../../../assets/xs_globe.png";
import FeaturedServices from "./FeaturedServices";

const Home = () => {
  const [text] = useTypewriter({
    words: ["Innovation", "Collaboration"],
    typeSpeed: 100,
    delaySpeed: 1000,
    loop: true,
    cursorStyle: "_",
    deleteSpeed: 50,
  });

  return (
    <Box
      sx={{
        background:
          "radial-gradient(39.33% 60.33% at 15% 60%, #1F3B2A 0%, #272A31 64%);",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    >
      <Navbar currentPage={"Home"} />

      <Box
        marginTop={{ xs: "100px", md: "0px" }}
        sx={{
          position: "absolute",
          top: {xs:"40%",md:"65%"},
          left: {xs:"48%",md:"35%"},
          transform: {
            xs: "translate(-50%, -93%)",
            // md: "translate(-94%, -59%)",
          },
          textAlign: "left",
          width: { xs: "90%", md: "55%" },
        }}
        zIndex={3}
      >
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Bold",
            fontSize: { xs: "1.8rem", md: "3.3rem" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: { xs: "normal", md: "53.364px" } /* 129.948% */,
            // letterSpacing: "-2.169px",
          }}
        >
          Empowering <span style={{ color: "#3FA268" }}>Research.</span>
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Bold",
            fontSize: { xs: "1.8rem", md: "3.3rem" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: { xs: "1.5 rem", md: "88.364px" },
            letterSpacing: " 1px",
          }}
        >
          Fueling&nbsp;
          <span style={{ color: "#3FA268" }}>{text}.</span>
          <Cursor />
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Regular",
            fontSize: { xs: "14px", md: " 18px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: { xs: "normal", md: "28.08px" } /* 143.265% */,
            letterSpacing: " 1px",
            marginTop: {xs:"10px",md:"20px"},
          }}
        >
          We bridge the gap, connecting visionaries, accelerating solutions, and
          catalyzing progress for a brighter tomorrow. Explore the future of R&D
          partnerships with us.
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          marginTop={{ xs: "30px", md: "30px" }}
          gap={3}
          justifyContent="flex-start"
          width="100vw"
        >
          <Button
            sx={{
              background: "#3FA268",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: { xs: "13px", md: "13px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28.08px",
              letterSpacing: " 1px",

              borderRadius: "5px",
              textAlign: "center",
              padding: { xs: "8px 25px", md: "10px 45px" },
              "&:hover": {
                background: "#3FA268",
              },
            }}
            onClick={() => {
              window.open(
                "https://calendly.com/shashank-reslink/30min",
                "_blank"
              );
            }}
          >
            Get Access
          </Button>
          <Button
            sx={{
              background: "transparent",
              border: "1px solid var(--Primary-White, #FEFEFE)",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: { xs: "13px", md: "13px" },

              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28.08px" /* 143.265% */,
              letterSpacing: "1px",

              borderRadius: "5px",
              textAlign: "center",
              padding: { xs: "8px 25px", md: "10px 45px" },
            }}
            onClick={() => {
              window.open(
                "https://calendly.com/shashank-reslink/30min",
                "_blank"
              );
            }}
          >
            KNOW MORE
          </Button>
        </Box>
      </Box>
      <Box
        display={{ xs: "none", md: "block" }}
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          transform: {
            xs: "translate(-138%, 20%) ", // Adding rotate(-30deg) for xs
            md: "translate(1%, 20%)",
            // lg: "translate(-10%, 20%)",
          },
          textAlign: "left",
          width: "44%",
        }}
      >
        <img
          src={globe}
          style={{
            display: { xs: "none", md: "block" },
            height: { xs: "10%", md: "125%" },
            width: { xs: "10%", md: "125%" },
            objectFit: "contain",
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        />
        {/* <img
          
          src={globe}
          style={{
            display: { xs: "block", md: "none" },
            height: { xs: "10%", md: "125%" },
            width: { xs: "10%", md: "125%" },
            objectFit: "contain",
            position: "relative" ,
            bottom: "0",
            right: "0",
          }}
        /> */}
      </Box>
      <Box
        display={{ xs: "block", md: "none" }}
        sx={{
          position: "absolute",
          bottom: "0",
          right: "0",
          transform: {
            xs: "translate(-138%, 20%) ", // Adding rotate(-30deg) for xs
            md: "translate(1%, 20%)",
            // lg: "translate(-10%, 20%)",
          },
          textAlign: "left",
          width: "54%",
        }}
      >
        {/* <img
          src={globe}
          style={{
            display: { xs: "none", md: "block" },
            height: { xs: "10%", md: "125%" },
            width: { xs: "10%", md: "125%" },
            objectFit: "contain",
            position: "absolute",
            bottom: "0",
            right: "0",
          }}
        /> */}
        <img
          src={globe}
          style={{
            display: { xs: "block", md: "none" },
            height: { xs: "5%", md: "125%" },
            width: { xs: "5%", md: "125%" },
            objectFit: "contain",
            position: "relative",
            bottom: "0",
            right: "0",
          }}
        />
      </Box>
      <BottomIcons />
      {/* <FeaturedServices /> */}
    </Box>
  );
};

export default Home;
