import React from "react";
import {
  Box,
  Typography,
  IconButton,
  Card,
  Grid,
  Divider,
} from "@mui/material";

const Projects = [
  {
    title: "Thermal protective performance and mult",
    status: "Resume Review",
    color: "#FDD26E",
  },
  {
    title:
      "Effect of heat flux and moisture content on the thermal protective performance of outer layer of fire pro",
    status: "Interview",
    color: "#CD7849",
  },
  {
    title: "Thermal protective performance and mult",
    status: "Task",
    color: "#73C2B4",
  },
  {
    title: "Testing and evaluation of functional textiles",
    status: "Payment",
    color: "#44C677",
  },
  {
    title: "Development of thermal liner for Extreme Heat Protective Clothing",
    status: "Closed",
    color: "#D9D9D9",
  },
];

const Activities = [
  {
    title: "Interview is scheduled for 12PM",
    time: "6:20PM",
    color: "#FDD26E",
  },
  {
    title: "Resume Review is scheduled for 12PM",
    time: "8:20PM",
    color: "#CD7849",
  },
  {
    title: "Task is scheduled for 12PM",
    time: "2:12PM",
    color: "#73C2B4",
  },
  {
    title: "Payment is scheduled for 12PM",
    time: "10:50AM",
    color: "#44C677",
  },
  {
    title: "Project is closed",
    time: "11:00AM",
    color: "#D9D9D9",
  },
];

const ProjectsActivities = () => {
  return (
    <Grid container spacing={2} flexGrow="1" padding="10px 0px">
      {/* First Card */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.13)",
            background: "rgba(255, 255, 255, 0.04)",
            backdropFilter: "blur(10.399999618530273px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box p={0}>
            <Box
              display="flex"
              padding="23px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Poppins",
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Posted Projects
              </Typography>
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  textDecoration: "underline",
                }}
              >
                See All
              </Typography>
            </Box>
            <Divider
              sx={{
                background: "rgba(255, 255, 255, 0.1)",
                borderBottomWidth: 2,
              }}
            />
            <Box padding="23px" overflowY="scroll">
              {Projects.map((project) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    background: "rgba(33, 40, 44, 0.50)",
                    borderRadius: "4px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    {project.title.length > 20
                      ? project.title.substring(0, 30) + "..."
                      : project.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "33%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                        background: project.color,
                        borderRadius: "50px",
                        padding: "8px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--Primary-White, #FEFEFE)",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      {project.status}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      </Grid>

      {/* Second Card */}
      <Grid item xs={12} md={6}>
        <Card
          sx={{
            borderRadius: "8px",
            border: "1px solid rgba(255, 255, 255, 0.13)",
            background: "rgba(255, 255, 255, 0.04)",
            backdropFilter: "blur(10.399999618530273px)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box p={0}>
            <Box
              display="flex"
              padding="23px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Poppins",
                  fontSize: "22px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "24px",
                }}
              >
                Activities
              </Typography>
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  textDecoration: "underline",
                }}
              >
                
              </Typography>
            </Box>
            <Divider
              sx={{
                background: "rgba(255, 255, 255, 0.1)",
                borderBottomWidth: 2,
              }}
            />
            <Box padding="23px" overflowY="scroll">
              {Activities.map((activity) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px",
                    justifyContent: "space-between",
                    marginBottom: "10px",
                    background: "rgba(33, 40, 44, 0.50)",
                    borderRadius: "4px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "80%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "auto",
                        height: "auto",
                        background: activity.color,
                        borderRadius: "50px",
                        padding: "8px",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--Primary-White, #FEFEFE)",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      {activity.title.length > 20
                        ? activity.title.substring(0, 30) + "..."
                        : activity.title}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    {activity.time}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProjectsActivities;
