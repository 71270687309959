import React from "react";
import { Box, Typography, Grid, Card, Divider } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import AboutPageIcon from "../../../assets/AboutPageIcon.png";
import "./AboutHeroStyle.css";

const AboutPoints = [
  "Budget management, timeliness and refining R&D problem statement for most appropriate approach.",
  "Pull together the right researchers, chemicals, labs, and equipment for your project efficiently.",
  "Deployment of capital in stages and milestone based documentation of entire project for a Better ROI",
];

const AboutGoalsContainer = () => {
  return (
    <Box
      justifyContent="center"
      alignItems="center"
      marginTop={{ xs: "0px", md: "0px" }}
      display="flex"
      sx={{
        flexDirection: {xs:"column",md:"row-reverse"},
        marginLeft: "auto",
        marginRight: "auto",
      }}
      width={{ xs: "100%", md: "90%" }}
    >
      <div className="logo-card-container">
        <img
          src={AboutPageIcon}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      </div>

      <Box
        width={{ xs: "100%", md: "50%" }}
        sx={{
          marginTop: { xs: "100px", md: "0px" },
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Bold",
            fontSize: { xs: "2rem", md: "55px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal" /* 129.948% */,
            letterSpacing: " 1px",

            marginBottom: "20px",
            marginLeft: "auto",
            marginRight: "auto",
            width: { xs: "90%", md: "auto" },
          }}
        >
          About Us
        </Typography>
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Regular",
            fontSize: { xs: "16px", md: " 18px" },
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: { xs: "normal", md: "26.4px" } /* 143.265% */,
            letterSpacing: " 1px",

            marginBottom: "30px",
            marginLeft: "auto",
            marginRight: "auto",
            width: { xs: "90%", md: "auto" },
          }}
        >
          Reslink is an AI-powered SaaS platform that helps companies accelerate
          their R&D process by refining problem statements, aligning the right
          resources, and achieving milestones on time and within budget.
        </Typography>
        <Box
          sx={{
            marginBottom: "20px",
            // marginLeft: "8px"
            marginLeft: "auto",
            marginRight: "auto",
            width: { xs: "90%", md: "auto" },
          }}
        >
          {AboutPoints.map((point) => (
            <Box
              sx={{
                display: "flex",
                gap: { xs: "0", md: "1" },
                alignItems: "center",
                textAlign: { xs: "left", md: "left" },
                marginLeft: { xs: "auto", md: "-3px" },
                marginBottom: "10px",
              }}
            >
              <CheckIcon sx={{ color: "#3FA268", fontSize: "18px" }} />
              <Typography
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                  fontFamily: "Gilroy-Regular",
                  fontSize: { xs: "14px", md: " 16px" },
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "26.08px" /* 143.265% */,
                  letterSpacing: " 1px",

                  marginLeft: "10px",
                }}
              >
                {point}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default AboutGoalsContainer;
