import { Card, Box, Typography, IconButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import React from "react";
import logo from "../assets/new_logo.png";
import { SocialIcons } from "../resources/Icons";
import send from "../assets/icon_images/send.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box width="100vw" sx={{ background: "#1F2129" }}>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          background: {
            xs: "radial-gradient(40% 20% at 50% 50%, rgba(0, 255, 101, 0.07) 30%, rgba(255, 255, 255, 0.01) 100%)",
            md: "radial-gradient(20% 40% at 50% 50%, rgba(0, 255, 101, 0.07) 30%, rgba(255, 255, 255, 0.01) 100%)",
          },
          backdropFilter: "blur(2.799999952316284px)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          // backdropFilter: "blur(3.5999999046325684px)",
          padding: { xs: "10px 0px 20px 0px", md: "50px 20px" },
          borderRadius: "0px",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Box
            textAlign="center"
            display="flex"
            flexDirection="column"
            flexGrow="1"
            alignItems="center"
          >
            <Box
              display="flex"
              flexDirection="column"
              width={{ xs: "100%", md: "50%" }}
              textAlign={{ xs: "center", md: "left" }}
              marginTop="20px"
              marginRight={{ xs: "0px", md: "0px" }}
            >
              <img
                src={logo}
                alt="logo"
                style={{
                  height: "44px",
                  width: "173.742px",
                  alignItems: { xs: "center", md: "flex-start" },
                  justifyContent: "center",
                  marginBottom: "30px",
                  marginLeft: { xs: "0%", md: "-4.5%" },
                }}
              />

              <Typography
                sx={{
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginLeft: { xs: "0%", md: "4.5%" },
                }}
              >
                Copyright © 2024 Reslink.
              </Typography>
              <Typography
                sx={{
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "24px",
                  marginLeft: { xs: "0%", md: "4.5%" },
                }}
              >
                All rights reserved
              </Typography>
              <Box
                display="flex"
                justifyContent={{ xs: "center", md: "flex-start" }}
                alignItems={{ xs: "center", md: "flex-start" }}
                marginTop={{ xs: "20px", md: "40px" }}
                marginLeft={{ xs: "8%", md: "3%" }}
              >
                {SocialIcons.map((item) => (
                  <img
                    onClick={() => window.open(item.link, "_blank")}
                    src={item.image}
                    alt={item.name}
                    style={{
                      height: "32px",
                      width: "32px",
                      marginRight: "15px",
                      cursor: "pointer",
                    }}
                  />
                ))}
              </Box>
            </Box>
          </Box>

          <Box
            textAlign={{ xs: "center", md: "left" }}
            gap={2}
            display="flex"
            flexDirection="column"
            marginTop={{ xs: "50px", md: "0px" }}
          >
            <Typography
              sx={{
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Medium",
                fontSize: " 20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                marginBottom: { xs: "0px", md: "20px" },
              }}
            >
              Company
            </Typography>
            <Link to="/about" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                About Us
              </Typography>
            </Link>
            <Link to="/features" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                Features
              </Typography>
            </Link>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                Contact Us
              </Typography>
            </Link>
          </Box>

          <Box
            textAlign={{ xs: "center", md: "left" }}
            gap={2}
            display="flex"
            flexDirection="column"
            marginLeft={{ xs: "0px", md: "150px" }}
            marginTop={{ xs: "50px", md: "0px" }}
          >
            <Typography
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Medium",
                fontSize: " 20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                marginBottom: { xs: "0px", md: "20px" },
              }}
            >
              Support
            </Typography>
            <Link to="/contact" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                Help
              </Typography>
            </Link>
            <Link to="/terms-of-service" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                Terms of Service
              </Typography>
            </Link>
            <Link to="/privacy-policy" style={{ textDecoration: "none" }}>
              <Typography
                sx={{
                  cursor: "pointer",
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "26px" /* 171.429% */,
                }}
              >
                Privacy Policy
              </Typography>
            </Link>
          </Box>

          <Box
            textAlign={{ xs: "center", md: "left" }}
            gap={2}
            width={{ xs: "100%", md: "30%" }}
            marginLeft={{ xs: "0", md: "80px" }}
            alignSelf={{ xs: "center", md: "flex-start" }}
            marginTop={{ xs: "50px", md: "19px" }}
          >
            <Typography
              sx={{
                textAlign: { xs: "center", md: "left" },

                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Medium",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                marginBottom: "20px",
              }}
            >
              Stay up to date
            </Typography>
            <TextField
              // className={classes.textField}
              size="small"
              sx={{
                borderRadius: "8px",
                opacity: 0.2,
                background: "var(--default-white, black)",
                width: "255px",
                height: "auto" /* Adjusted height */,
                flexShrink: 0,
              }}
              placeholder="Your email address"
              InputProps={{
                disableUnderline: true,
                style: { color: "#FEFEFE" },
                endAdornment: (
                  <InputAdornment position="end">
                    <img
                      src={send}
                      alt="send"
                      style={{
                        height: "18px",
                        width: "18px",
                        alignItems: "center",
                        marginBottom: "30px",
                        // background: "red",
                        marginTop: "28px",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              InputPropsClasses={{
                input: {
                  color: "var(--text-gray-300, #D9DBE1)",
                  fontFeatureSettings: "'liga' off",
                  fontFamily: "Gilroy-Regular",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Footer;
