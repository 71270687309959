import React from "react";
import {
  Box,
  Card,
  Divider,
  Typography,
  Button,
} from "@mui/material";
import Onboarding from "../components/Onboarding";
import OtpInput from "../components/OtpInput";

const IndustryOtp = () => {
  const EnterOtpCard = () => {
    const [otp, setOtp] = React.useState("");

    const handleChange = (newValue) => {
      setOtp(newValue);
    };

    const handleComplete = (value) => {
      /**
    value: "ABCD"
    **/
    };

    return (
      <Card
        sx={{
          maxWidth: 450,
          padding: "50px",
          borderRadius: "28px",
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "48px",
              textAlign: "center",
              marginBottom: "30px",
            }}
          >
            Enter Your OTP
          </Typography>

          <Divider
            variant="middle"
            sx={{ color: "#FFF", borderColor: "#FFF" }}
          />

          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <OtpInput />
          </Box>
          <Box
            sx={{
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              sx={{
                color: "#3CA465",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "23px",
                textAlign: "left",
                marginBottom: "40px",
              }}
            >
              Check your email
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              bgcolor: "#3FA268",
              color: "white",
              padding: "12px 70px 12px 70px",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "Poppins",
              fontWeight: "700",
              borderRadius: "10px   ",
              "&:hover": {
                backgroundColor: "#3FA268",
              },
            }}
          >
            Next
          </Button>
          <Box
            sx={{
              mt: "0.2rem",
              display: "flex",
              height: "100%",
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "#3CA465",
                fontFamily: "Poppins",
                fontSize: "0.7rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "150%" /* 0.9375rem */,
              }}
            >
              Having troubles?
            </Typography>
            <Typography
              sx={{
                paddingLeft: "0.5rem",
                color: "#FFF",
                fontFamily: "Poppins",
                fontSize: "0.68rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "150%" /* 0.9375rem */,
              }}
            >
              Get Help
            </Typography>
          </Box>
        </Box>
      </Card>
    );
  };
  return <Onboarding Component={EnterOtpCard} />;
};

export default IndustryOtp;
