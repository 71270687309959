import React from "react";
import { Box, Typography, Button, Card, IconButton } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import CheckIcon from "@mui/icons-material/Check";

const CardWithBigButton = ({ title, subtext }) => {
  return (
    <Card
      sx={{
        borderRadius: "20px",
        border: "1px solid rgba(255, 255, 255, 0.05)",
        background:
          "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 70%)",
        backdropFilter: "blur(3.5999999046325684px)",
        maxWidth: { xs: "93%", md: "75%" },
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          padding: { xs: "40px 18px", md: "80px 10px" },
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Box
          textAlign={{ xs: "center", md: "left" }}
          width={{ xs: "100%", md: "54%" }}
        >
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: { xs: "2.188rem", md: "40px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "normal" /* 129.948% */,
              letterSpacing: " 1px",

              textAlign: { xs: "center", md: "left" },
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              width: { xs: "100%", md: "80%" },
              textAlign: { xs: "center", md: "left" },
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Regular",
              fontSize: { xs: "16px", md: "18px" },
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "26.08px" /* 143.265% */,
              letterSpacing: " 1px",

              // marginTop: "23px",
              marginTop: { xs: "50px", md: "23px" },
            }}
          >
            {subtext}
          </Typography>
        </Box>
        <Box textAlign="left" marginTop={{ xs: "20px", md: "0px" }} 
          marginLeft={{ xs: "20px", md: "0px" }}
          // marginRight={{ xs: "auto", md: "auto" }}
        >
          <Button
            sx={{
              background: "#3FA268",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28.08px",
              letterSpacing: " 1px",

              marginTop: "30px",
              borderRadius: "5px",
              textAlign: "center",
              padding: "8px 35px",
              display: "flex",
              alignItems: "center", // Center text and icon vertically
              "&:hover": {
                background: "#3FA268",
              },
            }}
            onClick={() => {
              window.open(
                "https://calendly.com/shashank-reslink/30min",
                "_blank"
              );
            }}
          >
            Try for free
            <IconButton
   
   color="inherit"
              sx={{
                marginLeft: "3px", // Add some spacing between text and icon
              }}
            >
              <EastIcon /> {/* The left arrow icon */}
            </IconButton>
          </Button>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            width="250px"
          >
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",

                width: "80%",
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.08px" /* 143.265% */,
                letterSpacing: " 1px",

                marginTop: "10px",
              }}
            >
              <CheckIcon sx={{ fontSize: "16px" }} />
              No credit card
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                width: "80%",
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 14px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.08px" /* 143.265% */,
                letterSpacing: " 1px",
                marginTop: "10px",
              }}
            >
              <CheckIcon sx={{ fontSize: "16px" }} />
              Easy setup
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CardWithBigButton;
