import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./screens/Landing_Page/LandingPage";
import Team from "./screens/Team";
import Contact from "./screens/Contact_Page/Contact_Main";
import Navbar from "./components/Navbar";
import Features from "./screens/Features_Page/FeaturesMain";
import About from "./screens/About_Page/AboutMain";
import IndustryOtp from "./screens/IndustryOtp";
import IndustryIdentity from "./screens/IndustryIdentity";
import IndustryWorkBackground from "./screens/IndustryWorkBackground";
import IndustryWelcome from "./screens/IndustryWelcome";
import RNDReq from "./screens/RNDReq";
import RNDBg from "./screens/RNDBg";
import RNDGoals from "./screens/RNDGoals";
import TermsOfServiceMain from "./screens/Terms_of_service/TermsOfServiceMain";
import PrivacyPolicyMain from "./screens/Privacy_Policy/PrivacyPolicyMain";
import DashboardMain from "./screens/Dashboard/DashboardMain";
import ProjectsMain from "./screens/Projects_Screen/ProjectsMain";
import LoginMain from "./screens/Login_Page/LoginMain";
import Pricing from "./screens/Pricing_Page/PricingMain";
import "./App.css";
import StaffingMain from "./screens/Staffing_Page/StaffingMain";

import SignupMain from "./Onboarding/Signup_Page/SignupMain";

import ResearcherProfileDetails from "./screens/researcher/profile_details";
import ResearchAndExp from "./screens/researcher/research_and_exp";
import FinalizeProfile from "./screens/researcher/finalize_profile";
import ResearchProjects from "./screens/researcher/research_projects";
import ResearcherProfile from "./screens/researcher/ResearcherProfile";
import ForgotPasswordMain from "./screens/Forgot_Password_Page/ForgotPassword";
import ResetPasswordMain from "./screens/Reset_Password_Page/ResetPassword";

import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const location = useLocation();
  // const arr = ["/", "/home", "/team", "/contact", "/about", "/features"];

  // const navFunc = () => {
  //   return arr.includes(location.pathname);
  // };
  const [forgotPasswordClient, setForgotPasswordClient] = React.useState({
    email: "",
    userType: "",
    token: "",
    password: "",
  });

  return (
    <div className="App">
      {/* <Navbar /> */}
      {/* Signup, login, welcome */}
      {/* create a new comp for new project */}
      {/* popup that states company not registered yet */}

      <GoogleOAuthProvider clientId="476861631744-r3aoc164gseebl43gg57643fdb6l2f46.apps.googleusercontent.com">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/home" element={<LandingPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/features" element={<Features />} />
          {/* <Route path="/signup" element={<IndustrySignUp />} /> */}
          <Route path="/industry/otp" element={<IndustryOtp />} />
          <Route path="/industry/identity" element={<IndustryIdentity />} />
          <Route path="/industry/workbg" element={<IndustryWorkBackground />} />
          <Route path="/industry/welcome" element={<IndustryWelcome />} />
          <Route path="/create-new-rnd/requirements" element={<RNDReq />} />
          <Route path="/create-new-rnd/background" element={<RNDBg />} />
          <Route path="/create-new-rnd/goals" element={<RNDGoals />} />
          <Route path="/terms-of-service" element={<TermsOfServiceMain />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyMain />} />
          <Route path="/dashboard" element={<DashboardMain />} />
          <Route path="/projects" element={<ProjectsMain />} />
          <Route path="/signup" element={<SignupMain />} />
          <Route path="/staffing" element={<StaffingMain />} />
          <Route path="/login" element={<LoginMain />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route
            path="/researcher/profile-details"
            element={<ResearcherProfileDetails />}
          />
          <Route
            path="/researcher/research-and-exp"
            element={<ResearchAndExp />}
          />
          <Route
            path="/researcher/finalize-profile"
            element={<FinalizeProfile />}
          />
          <Route
            path="/researcher/research-projects"
            element={<ResearchProjects />}
          />
          <Route path="/researcher/profile" element={<ResearcherProfile />} />
          <Route path="/forgot-password" element={<ForgotPasswordMain />} />
          <Route
            path="/reset-password"
            element={
              <ResetPasswordMain
                forgotPasswordClient={forgotPasswordClient}
                setForgotPasswordClient={setForgotPasswordClient}
              />
            }
          />
        </Routes>
      </GoogleOAuthProvider>
    </div>
  );
}

export default App;
