import React from "react";
import { Box, Typography } from "@mui/material";
import { GoalsContainers } from "../../Landing_Page/components/GoalsContainers";
import charts from "../../../assets/charts.png";
import people_group from "../../../assets/people_group.png";
import deal from "../../../assets/deal.png";
import CardWithBigButton from "../../Landing_Page/components/CardWithBigButton";
import RatingCarousel from "../../Landing_Page/components/RatingCarousel";

const GoalPoints = [
  "Fostering collaboration for mutual growth.",
  "We connect industries with top-notch researchers.",
  "Reducing the time and effort required to identify candidates.",
];

const GoalPoints2 = [
  "Navigating R&D complexities with clear milestones.",
  "Empowering innovation through transparent progress tracking.",
];

const GoalPoints3 = [
  "Efficiency-driven solutions for your research and project success.",
  "Maximize your creative potential with Reslink's streamlined solutions.",
];

const FeaturesCommon = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "auto",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "80px 0px",
        textAlign: "center",
      }}
    >
      <GoalsContainers
        icon={charts}
        title="Tracking&nbsp;Progress: Turning Vision into Milestones"
        subtext="We've embraced the power of milestones in R&D. Each project is broken down into achievable steps, ensuring clarity and progress at every stage. With objective milestones, you'll always know where you stand on the path to innovation."
        points={GoalPoints2}
        slice_no={2}
      />
      <Box height="85px"></Box>
      <GoalsContainers
        icon={people_group}
        title="Connecting Visionaries with Opportunities"
        subtext="At Reslink, our Talent Acquisition service bridges the gap between forward-thinking researchers and progressive industries, providing flexible solutions that encompass both full-time positions and interim partnerships."
        points={GoalPoints}
        reverseOrder={true}
      />
      <Box height="85px"></Box>
      <GoalsContainers
        icon={deal}
        title="Empowering Progress Through Expertise and Efficiency"
        subtext="We drive innovation with collective knowledge and experience. We also understand the value of your time. That's why we automate time-consuming processes, allowing you to focus on research, projects, and your vision. We streamline tasks to help you unlock your creative potential."
        points={GoalPoints3}
      />
      <Box height={{ xs: "80px", md: "130px" }}></Box>

      {/* <RatingCarousel />
      <Box height="96px"></Box> */}

      <CardWithBigButton
        title={"Accelerate your R&D with Reslink. Join us today!"}
        subtext={
          "Bridging the Gap Between Researchers and Industries for a Better Tomorrow. "
        }
      />
    </Box>
  );
};

export default FeaturesCommon;
