import React from "react";
import { Box, Typography, Card } from "@mui/material";

export const FeaturedServicesCards = ({ icon, title, subtext }) => {
  return (
    <Card
      sx={{
        maxHeight: "380px",
        minHeight: "380px",
        width: { xs: "90%", md: "350px" },
        borderRadius: "10px",
        background: "var(--Primary-Black, #1F2129)",
        padding: { xs: "10px", md: "20px 0px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          padding: "30px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "20px",
          flex: "1 0 0",
        }}
      >
        <img
          src={icon}
          alt="featured_services_icon"
          width="48px"
          height="48px"
          style={{ flexShrink: 0 }}
        />
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Bold",
            fontSize: "27px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "32px" /* 129.948% */,
            letterSpacing: " 1px",

            textAlign: "left",
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Regular",
            fontSize: " 16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "23.08px" /* 143.265% */,
            letterSpacing: " 1px",
          }}
        >
          {subtext}
        </Typography>
      </Box>
    </Card>
  );
};
