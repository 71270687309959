import { Button } from "@mui/material";

const UserButton = ({ btnData }) => {
  return (
    <Button
      sx={{
        display: "flex",
        gap: "10px",
        backgroundColor:
          btnData.type == "solid" && "var(--Primary-Green, #3FA268)",
        padding: "9px",
        fontSize: "14px",
        fontWeight: "600",
        alignItems: "flex-start",
        color:
          btnData.success && btnData.type == "solid"
            ? "#fff"
            : "var(--Primary-Green, #3FA268)",
        borderRadius: "4px",
        border: "2px solid var(--Primary-Green, #3FA268)",
      }}
    >
      {btnData.text}
      {btnData.icon}
    </Button>
  );
};

export default UserButton;
