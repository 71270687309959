import React from "react";
import { Box, Typography} from "@mui/material";
import Navbar from "../../components/Navbar";

const HeadingText = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "var(--Primary-White, #FEFEFE)",
        fontFamily: "Gilroy-Bold",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "154%" /* 129.948% */,
        letterSpacing: "0.4px",
        textAlign: "left",
      }}
    >
      {text}
    </Typography>
  );
};

const BodyText = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "var(--Primary-White, #FEFEFE)",
        fontFamily: "Gilroy-Regular",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "154%" /* 129.948% */,
        letterSpacing: "0.4px",
        textAlign: "left",
      }}
    >
      {text}
    </Typography>
  );
};

const ListText = ({ points }) => {
  return (
    <ul
      style={{
        listStyleType: "disc",
        color: "#FEFEFE",
        margin: 0,
        padding: 0,
        marginLeft: "14px",
      }}
    >
      {points.map((point, index) => (
        <li key={index}>
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Regular",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "154%",
              letterSpacing: "0.4px",
              textAlign: "left",
            }}
          >
            {point}
          </Typography>
        </li>
      ))}
    </ul>
  );
};

const TermsOfServiceContent = () => {
  return (
    <Box
      sx={{
        // width: "100vw",
        height: "auto",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "120px 80px 80px 80px",
        // bgcolor: "red",
        // textAlign: "center",
      }}
    >
      <Navbar />
      <Box width="100%" textAlign="left">
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Medium",
            fontSize: "35px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "75px" /* 129.948% */,
            letterSpacing: "0.4px",
            textAlign: "left",
          }}
        >
          Terms Of Services
        </Typography>
        <Box height="30px"></Box>
        <HeadingText text="Last Updated: September 11, 2023" />
        <Box height="13px"></Box>
        <BodyText text="Welcome to Reslink! Please read these Terms of Service ('Terms') carefully before using our platform." />
        <Box height="23px"></Box>
        <HeadingText text="1. Acceptance of Terms" />
        <Box height="13px"></Box>
        <BodyText text="By accessing or using the Reslink platform, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use our platform." />
        <Box height="23px"></Box>
        <HeadingText text="2. Changes to Terms" />
        <Box height="13px"></Box>
        <BodyText text="Reslink reserves the right to modify or revise these Terms at any time. We will notify users of material changes. Your continued use of the platform following changes constitutes acceptance of the revised Terms." />
        <Box height="23px"></Box>
        <HeadingText text="3. User Eligibility" />
        <Box height="13px"></Box>
        <BodyText text="You must be at least 18 years old to use the Reslink platform. By agreeing to these Terms, you represent and warrant that you are at least 18 years old." />
        <Box height="23px"></Box>
        <HeadingText text="4. User Accounts" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "You are responsible for maintaining the confidentiality of your account information.",
            "You are solely responsible for any activities that occur under your account.",
            "You are solely responsible for any activities that occur under your account.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="5. Content" />
        <Box height="13px"></Box>

        <ListText
          points={[
            "Users are solely responsible for the content they post on Reslink.",
            "You agree not to post or transmit content that is unlawful, harmful, threatening, defamatory, obscene, or violates any third-party rights.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="6. Privacy" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Your use of Reslink is also governed by our Privacy Policy.",
            "By using the platform, you consent to the collection, use, and sharing of information as described in the Privacy Policy.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="7.  Intellectual Property" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Reslink retains ownership of all content, logos, trademarks, and intellectual property on the platform.",
            "Users may not use our branding without permission.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="8.  Prohibited Activities" />
        <Box height="13px"></Box>
        <BodyText text="You may not engage in any of the following activities on Reslink:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Violate any laws or regulations.",
            "Impersonate another person.",
            "Distribute spam, malware, or other harmful content.",
            "Interfere with the platform's functionality.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="9.  Dispute Resolution" />
        <Box height="13px"></Box>
        <BodyText
          text="Any disputes arising from the use of Reslink shall be governed by [Applicable Jurisdiction] law, and you agree to submit to the exclusive jurisdiction of the courts in [Applicable Jurisdiction].
"
        />
        <Box height="23px"></Box>
        <HeadingText text="10.  Termination" />
        <Box height="13px"></Box>
        <BodyText text="Reslink may terminate or suspend your account at any time without notice if you violate these Terms." />
        <Box height="23px"></Box>
        <HeadingText text="11.  Disclaimer of Warranties" />
        <Box height="13px"></Box>
        <BodyText text="Reslink provides the platform 'as is' and makes no warranties or representations about its accuracy, availability, or suitability for any purpose." />
        <Box height="23px"></Box>
        <HeadingText text="12.  Limitation of Liability" />
        <Box height="13px"></Box>
        <BodyText text="Reslink shall not be liable for any damages resulting from your use of the platform." />
        <Box height="23px"></Box>
        <HeadingText text="13.  Contact Information" />
        <Box height="13px"></Box>
        <BodyText text="If you have any questions about these Terms, please contact us at shashank@reslink.org ." />

      </Box>
    </Box>
  );
};

export default TermsOfServiceContent;
