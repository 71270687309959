import React from "react";
import {
  Box,
  Typography,
  Card,
  Grid,
  Divider,
  Chip,
  Stack,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";

const ProjectCards = ({ img, title, aors, status, color, updatedOn }) => {
  return (
    <Grid item xs={12} md={6}>
      <Card
        sx={{
          borderRadius: "8px",
          border: "1px solid rgba(255, 255, 255, 0.13)",
          background: "rgba(255, 255, 255, 0.04)",
          backdropFilter: "blur(10.399999618530273px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          //   height: "100%",
        }}
      >
        <Box p={0}>
          <Box padding="20px 20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img src={img} alt="project" />
                <Box marginLeft="10px">
                  <Typography
                    sx={{
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                    }}
                  >
                    {title}
                  </Typography>
                </Box>
              </Box>
              <MoreVertIcon
                sx={{
                  color: "var(--Primary-White, #FEFEFE)",
                }}
              />
            </Box>
            <Box height="23px"></Box>
            <Stack direction="row" spacing={1}>
              {aors.map((aor, index) => {
                return (
                  <Chip
                    key={index}
                    label={aor}
                    sx={{
                      background: "rgba(255, 255, 255, 0.1) ",
                      borderRadius: "50px",
                      color: "#FEFFEF",
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      padding: "0px",
                    }}
                  />
                );
              })}
            </Stack>
          </Box>

          <Divider
            sx={{
              background: "rgba(255, 255, 255, 0.1)",
              borderBottomWidth: 2,
            }}
          />
          <Box padding="10px 20px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "auto",
                    height: "auto",
                    background: color,
                    borderRadius: "50px",
                    padding: "10px",
                  }}
                />
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  {status}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <AssistantPhotoIcon
                  sx={{
                    color: "#3FA268",
                  }}
                />
                <Typography
                  sx={{
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                  }}
                >
                  {updatedOn}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};

export default ProjectCards;
