import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function updateResearcherProfile(data) {
  try {
    const endpoint = `${baseURL}/researcher/update_profile`;
    const response = await axios.put(endpoint, data,{
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("researcherToken")}`
        }
    });
    console.log("Response for update researcher is:", response.data);
    return "Request sent successfully!";
  } catch (error) {
    throw new Error(error.message);
  }
}

export default updateResearcherProfile;
