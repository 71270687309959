import React from "react";
import { Box, Typography } from "@mui/material";
import Navbar from "../../components/Navbar";

const HeadingText = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "var(--Primary-White, #FEFEFE)",
        fontFamily: "Gilroy-Bold",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "154%" /* 129.948% */,
        letterSpacing: "0.4px",
        textAlign: "left",
        textDecoration: "underline",
      }}
    >
      {text}
    </Typography>
  );
};

const BodyText = ({ text }) => {
  return (
    <Typography
      sx={{
        color: "var(--Primary-White, #FEFEFE)",
        fontFamily: "Gilroy-Regular",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "154%" /* 129.948% */,
        letterSpacing: "0.4px",
        textAlign: "left",
      }}
    >
      {text}
    </Typography>
  );
};

const ListText = ({ points }) => {
  return (
    <ul
      style={{
        listStyleType: "disc",
        color: "#FEFEFE",
        margin: 0,
        padding: 0,
        marginLeft: "14px",
      }}
    >
      {points.map((point, index) => (
        <li key={index}>
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Regular",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "154%",
              letterSpacing: "0.4px",
              textAlign: "left",
            }}
          >
            {point}
          </Typography>
        </li>
      ))}
    </ul>
  );
};

const PrivacyPolicyContent = () => {
  return (
    <Box
      sx={{
        // width: "100vw",
        height: "auto",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        padding: "120px 80px 80px 80px",
        // bgcolor: "red",
        // textAlign: "center",
      }}
    >
      <Navbar />
      <Box width="100%" textAlign="left">
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Gilroy-Medium",
            fontSize: "35px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "75px" /* 129.948% */,
            letterSpacing: "0.4px",
            textAlign: "left",
          }}
        >
          Privacy Policy
        </Typography>
        <Box height="30px"></Box>
        <HeadingText text="Introduction" />
        <Box height="13px"></Box>
        <BodyText
          text="Reslink. (“Reslink,” “we” or “us”) respect your privacy and are committed to protecting it through our compliance with this policy.
 "
        />
        <Box height="13px"></Box>
        <BodyText
          text="This policy describes the types of information we may collect from you (a guest user or registered user of www.science) (“you”) or that you may provide when you visit the website www..science (our “Website”) and our practices for collecting, using, maintaining, protecting, and disclosing that information.
 "
        />
        <Box height="13px"></Box>
        <BodyText
          text="This policy applies to information we collect:
    "
        />
        <Box height="13px"></Box>
        <ListText
          points={[
            "On this Website.",
            "In email, text, and other electronic messages between you and this Website.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText
          text="It does not apply to information collected by:
    "
        />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Us offline or through any other means, including on any other website operated by Reslink or any third party (including our affiliates and subsidiaries); or",
            "Any third party (including our affiliates and subsidiaries), including through any application or content (including advertising) that may link to or be accessible from or on the Website.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText
          text="Website including both scientists and researchers (“Researchers”) and companies (“Corporate Partners”) posting requests for proposals (“RFP”s).
Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website. By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates."
        />
        <Box height="23px"></Box>
        <HeadingText text="Children Under the Age of 18" />
        <Box height="13px"></Box>
        <BodyText text="Our Website is not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Website. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Website, register on the Website, or provide any information about yourself to us, including your name, email address, or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please contact us at shashank@reslink.org." />
        <Box height="13px"></Box>
        <BodyText text="We collect several types of information from and about users of our Website, including information:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "By which you may be personally identified, such as name, postal address, e-mail address, telephone number, or any other identifier by which you may be contacted online or offline (“personal information”);",
            "That is about you but individually does not identify you, and/or",
            "About your internet connection, the equipment you use to access our Website, and usage details.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="We collect this information:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Directly from you when you provide it to us.",
            "Automatically as you navigate through the site. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.",
            "From third parties, for example, our business partners.",
          ]}
        />

        <Box height="23px"></Box>
        <HeadingText text="Information You Provide to Us." />
        <Box height="13px"></Box>
        <BodyText text="The information we collect on or through our Website may include:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.",
            "Records and copies of your correspondence (including email addresses), if you contact us.",
            "Your responses to surveys that we might ask you to complete for research purposes.",
            "Details of transactions you carry out through our Website and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our Website.",
            "Your search queries on the Website.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="You also may provide profile information to be published or displayed (hereinafter, “posted”) on areas of the Website accessible to registered Researchers and Corporate Partners, Corporate Partner Opportunities posted on areas of the Website accessible to registered Researchers, or communications submitted by you for transmission to a specific Researcher or Corporate Partner (collectively, “User Contributions”). Your User Contributions are posted on the Website and transmitted to others at your own risk. Although we limit access to certain pages to registered users and facilitate certain private communications, please be aware that no security measures are perfect or impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons." />
        <Box height="23px"></Box>
        <HeadingText text="Information We Collect Through Automatic Data Collection Technologies." />
        <Box height="13px"></Box>
        <BodyText text="As you navigate through and interact with our Website, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.",
            "Information about your computer and internet connection, including your IP address, operating system, and browser type.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="The information we collect automatically may include personal information or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Website and to deliver a better and more personalized service, including by enabling us to:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Estimate our audience size and usage patterns.",
            "Store information about your preferences, allowing us to customize our Website according to your individual interests.",
            "Speed up your searches.",
            "Recognize you when you return to our Website.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="The technologies we use for this automatic data collection may include:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "Cookies (or browser cookies). A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.",
            "Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies.",
            "Web Beacons. Pages of our the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="Third-Party Use of Cookies and Other Tracking Technologies." />
        <Box height="13px"></Box>
        <BodyText text="Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content." />
        <Box height="13px"></Box>
        <BodyText text="We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly." />
        <Box height="23px"></Box>
        <HeadingText text="How We Use Your Information." />
        <Box height="13px"></Box>
        <BodyText text="We use information that we collect about you or that you provide to us, including any personal information:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "To present our Website and its contents to you.",
            "To provide you with information, products, or services that you request from us.",
            "To fulfill any other purpose for which you provide it.",
            "To provide you with notices about your account.",
            "To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.",
            "To notify you about changes to our Website or any products or services we offer or provide though it.",
            "To allow you to participate in interactive features on our Website.",
            "In any other way we may describe when you provide the information.",
            "For any other purpose with your consent.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="We may also use your information to contact you about our own and third-parties' goods and services that may be of interest to you. If you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data (the registration form)." />
        <Box height="13px"></Box>
        <BodyText text="We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria." />
        <Box height="23px"></Box>
        <HeadingText text="Disclosure of Your Information." />
        <Box height="13px"></Box>
        <BodyText text="We may disclose aggregated information about our users, and information that does not identify any individual, without restriction." />
        <Box height="13px"></Box>
        <BodyText text="We may disclose personal information that we collect or you provide as described in this privacy policy:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "To our subsidiaries and affiliates.",
            "To contractors, service providers, and other third parties we use to support our business.",
            "To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Reslink's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Reslink about our Website users is among the assets transferred.",
            "To third parties to market their products or services to you if you have not opted out of these disclosures. We contractually require these third parties to keep personal information confidential and use it only for the purposes for which we disclose it to them.",
            "To fulfill the purpose for which you provide it. For example, if you give us an email address to use the “email a friend” feature of our Website, we will transmit the contents of that email and your email address to the recipients.",
            "For any other purpose disclosed by us when you provide the information.",
            "With your consent.",
          ]}
        />
        <Box height="13px"></Box>
        <BodyText text="We may also disclose your personal information:" />
        <Box height="13px"></Box>
        <ListText
          points={[
            "To comply with any court order, law, or legal process, including to respond to any government or regulatory request.",
            "To enforce or apply our terms of use and other agreements, including for billing and collection purposes.",
            "If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Reslink, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.",
          ]}
        />
        <Box height="23px"></Box>
        <HeadingText text="Contact Information." />
        <Box height="13px"></Box>
        <BodyText
          text="To ask questions or comment about this privacy policy and our privacy practices, contact us at: shashank@reslink.org
    "
        />
        <Box height="23px"></Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicyContent;
