import React from "react";
import { Box, styled, InputBase } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 5,
    position: "relative",
    border: "1px solid rgba(255, 255, 255, 0.25)",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 5,
      border: "1px solid rgba(255, 255, 255, 0.25)",
      boxShadow: "0 0 0 0.2rem rgba(255, 255, 255, 0.25)",
    },
  },
  "& .MuiSelect-icon": {
    color: "#FFF", // Change the color of the dropdown arrow to white
  },
}));

const RNDSelectMenu = ({
  selectedItem,
  setSelectedItem,
  dropdownList,
  id
}) => {
  return (
    <Box width="100%" marginTop="10px" marginLeft="-9px" id={id}>
      <FormControl
        sx={{
          m: 1,
          width: "55%",
        }}
        variant="standard"
      >
        <Select
          labelId="demo-simple-select-label"
          id={id}
          placeholder={id}
          value={selectedItem}
          onChange={(e) => setSelectedItem(e.target.value)}
          input={<BootstrapInput />}
          sx={{ color: "#FFF" }}
        >
          {dropdownList.map((item) => (
            <MenuItem value={item} key={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default RNDSelectMenu;
