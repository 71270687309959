import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function LoginResearcher(data) {
  console.log("Data for researcher is at login:", data);
  try {
    const endpoint = `${baseURL}/researcher/login`;
    const response = await axios.post(endpoint, data);
    console.log("Response for researcher is at login fro:", response.data);
    localStorage.setItem("researcherToken", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export default LoginResearcher;