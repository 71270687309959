import nasscom from "../assets/icon_images/nasscom.png";
import charInno from "../assets/icon_images/chakr.png";
import clarico from "../assets/icon_images/clarico.png";
import dharaksha from "../assets/icon_images/dharaksha.png";
import impacto from "../assets/icon_images/impacto.png";
import loka from "../assets/icon_images/loka.png";
import yoga from "../assets/icon_images/yoga.png";
// import image6 from "../assets/icon_images/Home/image_6.png";

import Instagram from "../assets/social_icons/Instagram.png";
import Dribble from "../assets/social_icons/Dribbble.png";
import Twitter from "../assets/social_icons/Twitter.png";
import Youtube from "../assets/social_icons/Youtube.png";
import linkedin from "../assets/social_icons/linkedin.png";
import all_logos from "../assets/icon_images/all_logo.png";

export const IconArray = [
  {
    name: "NASSCOM",
    image: nasscom,
  },
  {
    name: "CharInno",
    image: charInno,
  },
  {
    name: "Clarico",
    image: clarico,
  },
  {
    name: "Dharaksha",
    image: dharaksha,
  },
  {
    name: "Impacto",
    image: impacto,
  },
  {
    name: "Loka",
    image: loka,
  },
  {
    name: "Yoga",
    image: yoga,
  },
];

export const SocialIcons = [
  {
    name: "Instagram",
    image: Instagram,
    link: "https://www.instagram.com/reslinkorg/",
  },
  {
    name: "Twitter",
    image: Twitter,
    link: "https://twitter.com",
  },
  {
    name: "Youtube",
    image: Youtube,
    link: "https://in.linkedin.com/company/reslink2023?trk=public_jobs_topcard_logo",
  },
];
