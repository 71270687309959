import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import image from "../assets/onboarding_vector.svg";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Onboarding = ({ Component }) => {
  const [text] = useTypewriter({
    words: ["Innovation", "Collaboration"],
    typeSpeed: 100, // Adjust the typing speed as needed
    delaySpeed: 1000, // Adjust the delay between words
    loop: true,
    cursorStyle: "_",
    deleteSpeed: 50,
  });
  return (
    <Box
      sx={{
        background:
          "radial-gradient(71.33% 72.1% at 0% 89%, #1F3B2A 0%, #111418 100%);",
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          height: "100vh",
          width: "100vw",

          //   marginLeft: { xs: "0px", md: "80px" },
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Grid
          container
          spacing={{ xs: 0, md: 4 }}
          justifyContent="center"
          alignItems="center"
          height="100%"
          padding={5}
        >
          <Grid
            item
            md={6}
            sx={{
              marginBottom: { xs: "50px", md: "0px" },
            }}
          >
            <Box
              width={{ xs: "100%", md: "100%" }}
              height="100%"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={image}
                alt="feature_image"
                width="213.573px"
                height="165.875px"
                style={{
                  flexShrink: 0,
                  marginBottom: "20px",
                }}
              />
              <Typography
                sx={{
                  width: "100%",
                  color: "white",
                  fontSize: { xs: "20px", md: "35px" },
                  fontFamily: "Manrope",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Empowering <span style={{ color: "#3FA268" }}>Research.</span>
              </Typography>
              <Typography
                sx={{
                  width: "100%",
                  color: "white",
                  fontSize: { xs: "20px", md: "35px" },
                  fontFamily: "Manrope",
                  fontWeight: "800",
                  textAlign: "center",
                }}
              >
                Fueling&nbsp;
                <span style={{ color: "#3FA268" }}>{text}.</span>
                <Cursor />
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Component />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Onboarding;
