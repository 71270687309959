import React from "react";
import { Box} from "@mui/material";
import Footer from "../../components/Footer";
import TermsOfServiceContent from "./TermsOfServiceContent";

const TermsOfServiceMain = () => {
  return (
    <Box sx={{ overflowY: "scroll", overflowX: "hidden" }} height="100vh">
      <TermsOfServiceContent />
      <Footer />
    </Box>
  );
};

export default TermsOfServiceMain;
