import React from "react";
import { Box, Typography, Grid, Card } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

export const GoalsContainers = ({
  icon,
  title,
  subtext,
  points,
  reverseOrder,
}) => {
  return (
    <Box>
      <Grid
        container
        spacing={{ xs: 0, md: "auto" }}
        justifyContent="center"
        alignItems="center"
        height="100%"
        display="flex"
        sx={{
          flexDirection: reverseOrder === true ? "row-reverse" : "row",
        }}
      >
        <Grid item md={6}>
          <Card
            sx={{
              // marginLeft: reverseOrder === true ? "11%" : "30%",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.05)",
              background: "rgba(255, 255, 255, 0.03)",
              backdropFilter: "blur(3.5999999046325684px)",
              width: { xs: "16.5rem", md: "50%" },
              textAlign: "center",
              padding: "40px 20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <img src={icon} height="100%" width="100%" />
          </Card>
        </Grid>
        <Grid item md={6}>
          <Box
            marginTop={{ xs: "20px", md: "0px" }}
            textAlign="left"
            width={{ xs: "90%", md: "70%" }}
            // sx={{ marginLeft: reverseOrder === true ? "29.2%" : "-5%" }}
            sx={{
              marginLeft:
                reverseOrder === true
                  ? { xs: "5%", md: "25.2%" }
                  : { xs: "7%", md: "8%" },

              // marginRight: reverseOrder === true ? "0%" : "30%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Primary-White, #3FA268)",
                fontFamily: "Gilroy-Bold",
                fontSize: { xs: "1.2rem", md: "30px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal" /* 129.948% */,
                letterSpacing: " 1px",

                textAlign: "left",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                width: {xs:"90%", md:"auto"},
              }}
            >
              {title.split(" ")[0]}
              <span style={{ color: "#FEFEFE" }}>
                &nbsp;{title.split(" ").slice(1).join(" ")}
              </span>
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: { xs: "14px", md: "16px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.4px" /* 143.265% */,
                letterSpacing: " 1px",

                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
                width: {xs:"90%", md:"auto"},
              }}
            >
              {subtext}
            </Typography>
            {points.map((point) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1",
                  alignItems: "center",
                  textAlign: "left",
                  marginLeft: "-3px",
                }}
              >
                <CheckIcon
                  sx={{
                    color: "#3FA268",
                    fontSize: "16px",
                    marginBottom: "10px",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Gilroy-Medium",
                    fontSize: { xs: "14px", md: "16px" },
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "26.08px" /* 143.265% */,
                    letterSpacing: " 1px",

                    marginLeft: "10px",
                    marginBottom: "13px",
                  }}
                >
                  {point}
                </Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
