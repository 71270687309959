import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function createIndustryProfile(data) {
  console.log("Data for industry is:", data);
  try {
    const endpoint = `${baseURL}/industry/add_profile`;
    const response = await axios.post(endpoint, data);
    console.log("Response for industry is:", response.data);
    localStorage.setItem("industryToken", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export default createIndustryProfile;
