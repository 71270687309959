import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function resetPasswordHelper(data) {
    console.log("Data for reset password is:", data);
    try {
        const endpoint = `${baseURL}/forgot_password/reset_password`;
        const response = await axios.post(endpoint, data);
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.log("Error in resetPasswordHelper", error.response.data.error);
        throw new Error(error.response.data.error);
    }
}

export default resetPasswordHelper;