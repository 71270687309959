import React from "react";
import { Box, Typography } from "@mui/material";

const LargeTextSmallText = ({ mainText, required }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        sx={{
          color: "#FFF",
          fontFamily: "Poppins",
          fontSize: "24px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "36px",
        }}
      >
        {mainText}
      </Typography>
      <Typography
        sx={{
          color: "rgba(255, 255, 255, 0.25);",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "21px",
          marginLeft: "15px",
        }}
      >
        {required === true ? "Required" : "Optional"}
      </Typography>
    </Box>
  );
};

export default LargeTextSmallText;
