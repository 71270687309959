import React from "react";
import { Box, Card, Typography } from "@mui/material";

const InputField = (props) => {
    const {label, placeholder, value, onChange, required} = props;
    return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            {required ? `${label}*` : label}
          </Typography>
          <input
            type="text"
            value={value}
            onChange={onChange}
            style={{
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              display: "flex",
              padding: "12px 14px",
              alignItems: "center",
              gap: "8px",
              width: "90%",
            }}
            placeholder={placeholder}
          />
        </Box>
    )
}

export default InputField;