import React from "react";
import { Box, Card, IconButton, Typography } from "@mui/material";
import BackgroundComp from "./components/background_comp";
import SidePanel from "./components/side_panel";
import file_icon from "../../assets/file.png";
import InputField from "./components/input_field";
import "./SearchBox.css";
import trash_icon from "../../assets/trash.png";
import red_trash_icon from "../../assets/trash.png";
import updateResearcherProfile from "../../helper/updateResearcherProfile";
import { useNavigate } from "react-router-dom";
import bucketUploader from "../../helper/bucketUploader";
import { red } from "@mui/material/colors";
import { CircularProgress } from "@mui/material";

const ProjectTile = ({
  project,
  index,
  image,
  data,
  setData,
  setImages,
  images,
  imageFiles,
  setImageFiles,
}) => {
  const { project_image, project_name, project_description } = project;
  return (
    <Card
      elevation="0"
      sx={{
        // display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "95%",
        maxHeight: "350px",
        // gap: "16px",
        marginLeft: "auto",
        marginRight: "auto",
        // padding: "24px",
        borderRadius: "11px",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        background: "var(--Base-White, #FFF)",
        border: "0.978px solid var(--Gray-200, #EAECF0)",
        paddingBottom: "10px",
        // bgcolor: "blue",
      }}
    >
      <img
        src={image} // Ensure this URL is valid
        alt="project"
        style={{
          width: "100%",
          height: "80%",
          // borderRadius: "50%",
        }}
      />
      <Box
        sx={{
          width: "100%",
          padding: "0px 10px 10px 8px",
          // bgcolor: "red",
          // display: "flex",
          // flexDirection: "column",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          // paddingRight="20px"
        >
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,

              // lineHeight: "30px",
            }}
          >
            {project_name}
          </Typography>
          <IconButton
            sx={{
              marginRight: "10px",
            }}
            onClick={() => {
              const newProjects = data.research_projects.filter(
                (p, i) => i !== index
              );
              setData({ ...data, research_projects: newProjects });
              const newImages = images.filter((img, i) => i !== index);
              setImages(newImages);
              const newImageFiles = imageFiles.filter((img, i) => i !== index);
              setImageFiles(newImageFiles);
            }}
          >
            <img src={red_trash_icon} alt="trash" height="18px" />
          </IconButton>
        </Box>

        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",

            fontFamily: "Poppins",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: 400,
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // lineHeight: "26px",
            width: "98%",
          }}
        >
          {project_description}
        </Typography>
      </Box>
    </Card>
  );
};

const LinkView = ({ link, index, setData, data, links, listName }) => {
  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      sx={{
        borderRadius: "8px",
        border: "1px solid var(--Gray-300, #D0D5DD)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        display: "flex",
        padding: "12px 14px",
        alignItems: "center",
        width: "90%",
      }}
    >
      <Typography
        sx={{
          color: "var(--Gray-700, #344054)",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
        }}
      >
        {link}
      </Typography>
      <img
        src={red_trash_icon}
        alt="trash"
        onClick={() => {
          const newLinks = links.filter((l, i) => i !== index);
          setData({ ...data, [listName]: newLinks });
        }}
      />
    </Box>
  );
};

const ProjectForm = ({
  project,
  setProject,
  projectKeyword,
  setProjectKeyword,
  data,
  setData,
  images,
  setImages,
  setShowForm,
  imageFiles,
  setImageFiles,
}) => {
  const handleSaveProject = async () => {
    if (project.project_name === "") {
      alert("Project name is required");
      return;
    }
    if (project.project_description === "") {
      alert("Project description is required");
      return;
    }
    if (project.project_image === "") {
      alert("Project image is required");
      return;
    }
    if (project.project_url === "") {
      alert("Project url is required");
      return;
    }
    if (project.project_keywords.length === 0) {
      alert("Project keywords are required");
      return;
    }
    console.log("Project", project);
    const newProjects = [...data.research_projects, project];
    setData({ ...data, research_projects: newProjects });
    setProject({
      project_name: "",
      project_description: "",
      project_image: "",
      project_category: "",
      project_url: "",
      project_keywords: [],
    });
    var newImage = document.getElementById("photoInput");
    newImage = URL.createObjectURL(newImage.files[0]);
    setImages([...images, newImage]);
    newImage = document.getElementById("photoInput").files[0];
    setImageFiles([...imageFiles, newImage]);
    console.log("Images", images);
    console.log("Data", data);
    console.log("Image Files", imageFiles);
    setShowForm(false);
    // console.log("Photo", document.getElementById("photoInput").files[0]);
    // await bucketUploader({
    //   fileInput: document.getElementById("photoInput").files[0],
    // });
  };

  const handleCancel = () => {
    setProject({
      project_name: "",
      project_description: "",
      project_image: "",
      project_category: "",
      project_url: "",
      project_keywords: [],
    });
    setShowForm(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      <InputField
        label="Research Category"
        placeholder="Enter research category"
        required={true}
        value={project.project_category}
        onChange={(e) => {
          setProject({ ...project, project_category: e.target.value });
        }}
      />
      <InputField
        label="Project Name"
        placeholder="Enter project name"
        required={true}
        value={project.project_name}
        onChange={(e) => {
          setProject({ ...project, project_name: e.target.value });
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "6px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Project Description*
        </Typography>
        <textarea
          rows={4}
          style={{
            borderRadius: "8px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            padding: "12px 14px",
            width: "90%",
            height: "100px",
            resize: "none",
          }}
          placeholder="Enter your project description"
          value={project.project_description}
          onChange={(e) => {
            setProject({ ...project, project_description: e.target.value });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "6px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Upload Project Image*
        </Typography>
        <input
          id="photoInput"
          type="file"
          accept="image/jpeg, image/png, image/jpg"
          style={{
            borderRadius: "8px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            padding: "12px 14px",
            width: "90%",
          }}
          placeholder="Choose your project image"
          value={project.project_image}
          onChange={(e) => {
            setProject({ ...project, project_image: e.target.value });
          }}
        />
      </Box>
      <InputField
        label="Project URL"
        placeholder="Enter project url"
        required={true}
        value={project.project_url}
        onChange={(e) => {
          setProject({ ...project, project_url: e.target.value });
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "6px",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Project Keywords*
        </Typography>

        <div class="searchbox-wrap">
          <input
            name="project_keywords"
            type="text"
            placeholder="Add project keywords"
            value={projectKeyword}
            onChange={(e) => {
              setProjectKeyword(e.target.value);
            }}
          />
          <button
            onClick={() => {
              if (projectKeyword != "") {
                setProject({
                  ...project,
                  project_keywords: [
                    ...project.project_keywords,
                    projectKeyword,
                  ],
                });
                setProjectKeyword("");
              }
              setProjectKeyword("");
            }}
          >
            Add
          </button>
        </div>
        {project.project_keywords.map((link, index) => (
          <LinkView
            key={index}
            link={link}
            index={index}
            setData={setProject}
            data={project}
            links={project.project_keywords}
            listName="project_keywords"
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "8px",
          width: "100%",
        }}
      >
        <button
          onClick={handleSaveProject}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            //   width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Brand-600, #099250)",
            background: "var(--Brand-600, #099250)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Base-White, #FFF)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Save Project
          </Typography>
        </button>
        <button
          onClick={handleCancel}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            //   width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Cancel
          </Typography>
        </button>
      </Box>
    </Box>
  );
};

const FinalComponent = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    research_projects: [],
    stage: 2,
  });
  const [project, setProject] = React.useState({
    project_name: "",
    project_description: "",
    project_image: "",
    project_category: "",
    project_url: "",
    project_keywords: [],
  });

  const [images, setImages] = React.useState([]);

  const [projectKeyword, setProjectKeyword] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [showForm, setShowForm] = React.useState(true);

  const [imageFiles, setImageFiles] = React.useState([]);

  const handleContinue = async () => {
    try {
      setLoading(true);

      for (var i = 0; i < imageFiles.length; i++) {
        const resp = await bucketUploader({
          fileInput: imageFiles[i],
          tag: "research_project",
        });
        data.research_projects[i].project_image = resp;
      }
      console.log("Data", data);
      const res = await updateResearcherProfile(data);
      console.log("Response", res);
      navigate("/researcher/finalize-profile");
    } catch (e) {
      console.log("Error", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        // bgcolor: "red",
      }}
    >
      <Card
        elevation="0"
        width="52px"
        height="52px"
        sx={{
          padding: "12px 14px",
          borderRadius: "10px",
          border: "1px solid var(--Gray-200, #EAECF0)",
        }}
      >
        <img src={file_icon} alt="icon" />
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "360px",
          boxShadow: "none",
          bgcolor: "transparent",
          gap: "24px",
          border: "none",
          // overflow: "scroll",
          // height: "20rem",
        }}
      >
        {/* <img src={logo} alt="" /> */}
        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "30px",
          }}
        >
          Research Projects
        </Typography>
        <Typography
          sx={{
            color: "var(--Gray-600, #475467)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            // lineHeight: "26px",
          }}
        >
          Add atleast 1 research project
        </Typography>
        {data.research_projects.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              gap: "24px",
              padding: "10px",
              width: "100%",
            }}
          >
            {data.research_projects.map((project, index) => (
              <ProjectTile
                key={index}
                project={project}
                index={index}
                image={images[index]}
                data={data}
                setData={setData}
                setImages={setImages}
                images={images}
                imageFiles={imageFiles}
                setImageFiles={setImageFiles}
              />
            ))}
            <button
              onClick={() => setShowForm(true)}
              style={{
                display: "flex",
                padding: "7px 16px",
                justifyContent: "center",
                alignItems: "center",
                gap: "6px",
                //   width: "100%",
                borderRadius: "8px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                flexGrow: 1,
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Add More
              </Typography>
            </button>
          </Box>
        ) : null}

        {showForm || data.research_projects.length === 0 ? (
          <ProjectForm
            project={project}
            setProject={setProject}
            projectKeyword={projectKeyword}
            setProjectKeyword={setProjectKeyword}
            data={data}
            setData={setData}
            images={images}
            setImages={setImages}
            setShowForm={setShowForm}
            imageFiles={imageFiles}
            setImageFiles={setImageFiles}
          />
        ) : null}

        {data.research_projects.length > 0 ? (
          <button
            onClick={handleContinue}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Continue
              </Typography>
            )}
          </button>
        ) : null}
      </Card>
    </Box>
  );
};
const ResearchProjects = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
      }}
    >
      <SidePanel selectedIndex={2} />
      <BackgroundComp Component={FinalComponent} />
    </Box>
  );
};

export default ResearchProjects;
