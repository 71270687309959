import React from "react";
import { Box, Typography, LinearProgress, colors } from "@mui/material";

const ProgressBar = ({ stepList, progress , stepIndex}) => {
  return (
    <Box width="100%">
      <Box
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        gap={10}
      >
        {stepList.map((step, index) => (
          <Typography
            id={step}
            sx={{
              color: index===stepIndex?"#3FA268":"#FFF",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "24px",
              marginBottom: "20px",
            }}
          >
            {step}
          </Typography>
        ))}
      </Box>
      <Box sx={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          color="success"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.12);",
            height: "5px"
          }}
        />
      </Box>
    </Box>
  );
};

export default ProgressBar;
