import React from "react";
import { Box } from "@mui/material";
import Footer from "../../components/Footer";
import PrivacyPolicyContent from "./PrivacyPolicyContent";

const PrivacyPolicyMain = () => {
  return (
    <Box sx={{ overflowY: "scroll", overflowX: "hidden" }} height="100vh">
      <PrivacyPolicyContent />
      <Footer />
    </Box>
  );
};

export default PrivacyPolicyMain;
