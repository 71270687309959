import axios from "axios";
import baseURL from "../resources/BaseUrl";

const GetResearcherProfile = async ({ uuid }) => {
  console.log("helper UUID is:", uuid);
  try {
    const endpoint = `${baseURL}/researcher/get_profile?uuid=${uuid}`;
    console.log("Endpoint for researcher profile is:", endpoint);
    const response = await axios.get(endpoint);
    console.log("Response for researcher profile is:", response.data);
    return response.data;
  } catch (error) {
    console.log("Error for researcher fetch profile is:", error.message);
    throw new Error(error.message);
  }
};

export default GetResearcherProfile;
