import React from "react";
import { Box, Typography, Button } from "@mui/material";
import BackgroundScreen from "../components/BackgroundScreen";
import { useNavigate } from "react-router-dom";

const IndustryWelcome = () => {
  const navigate = useNavigate();

  const WelcomeComponent = () => {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -63%)",
            md: "translate(-50%, -50%)",
          },
          textAlign: "center",
        }}
      >
        <Typography
          sx={{
            width: "100%",
            color: "white",
            fontSize: { xs: "30px", md: "50px" },
            fontFamily: "Poppins",
            fontWeight: "700",
          }}
        >
          Welcome to <span style={{ color: "#3FA268" }}>Reslink!</span>
        </Typography>

        <Typography
          sx={{
            display: { xs: "none", md: "block" },
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "16px",
            marginTop: "60px",
          }}
        >
          Reslink simplifies the process of sharing collaboration opportunities,
          handling project proposals, and connecting with fellow researchers.
        </Typography>
        <Typography
          sx={{
            display: { xs: "none", md: "block" },
            color: "white",
            fontFamily: "Poppins",
            fontWeight: "600",
            fontSize: "16px",
            marginTop: "4px",
          }}
        >
          See how other sponsors are using Reslink in the community.
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          marginTop="80px"
          gap={5}
          justifyContent="center"
          alignItems="center"
          width="100vw"
        >
          <Button
            sx={{
              background: "#3FA268",
              color: "white",
              fontFamily: "Manrope",
              fontSize: "15px",
              fontWeight: "700",
              textTransform: "none",
              borderRadius: "5px",
              textAlign: "center",
              "&:hover": {
                background: "#3FA268",
              },
            }}
            onClick={() => {
              navigate("/create-new-rnd/background");

            }}
          >
            Create your first R&D project
          </Button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Button
              sx={{
                color: "#3FA268",
                border: "1px solid #3FA268",
                borderRadius: "5px",
                fontFamily: "Manrope",
                fontSize: "15px",
                fontWeight: "700",
                textTransform: "none",
                display: "flex",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={() => {
                // window.open(
                //   "https://calendly.com/shashank-reslink/30min",
                //   "_blank"
                // );
              }}
            >
              Create your first HR request
            </Button>
          </Box>
          <Button
            sx={{
              color: "#3FA268",
              border: "1px solid #3FA268",
              borderRadius: "5px",
              fontFamily: "Manrope",
              fontSize: "15px",
              fontWeight: "700",
              textTransform: "none",
              display: "flex",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => {
              // window.open(
              //   "https://calendly.com/shashank-reslink/30min",
              //   "_blank"
              // );
            }}
          >
            Explore the dashboard
          </Button>
        </Box>
      </Box>
    );
  };
  return <BackgroundScreen Component={WelcomeComponent} Title={""} />;
};

export default IndustryWelcome;
