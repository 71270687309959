import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import Onboarding from "../components/Onboarding";

const IndustryIdentity = () => {
  const [selectedValue, setSelectedValue] = useState("");
  const IndustryTypes = ["Company", "SME", "Startup"];

  const CreateIdentityCard = () => {
    const handleRadioChange = (event) => {
      setSelectedValue(event.target.value);
    };

    return (
      <Card
        sx={{
          maxWidth: 450,
          padding: "50px",
          borderRadius: "28px",
          background:
            "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            height: "100%",
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "48px",
              textAlign: "center",
            }}
          >
            Who are you?
          </Typography>
          <Typography
            sx={{
              color: "#3CA465",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "23px",
              textAlign: "center",
              marginBottom: "40px",
            }}
          >
            Industries
          </Typography>
          <RadioGroup value={selectedValue} onChange={handleRadioChange}>
            <Box display="flex" justifyContent="space-between" width="100%">
              {IndustryTypes.map((type) => (
                <Box
                  sx={{
                    border: "1.143px solid rgba(255, 255, 255, 0.25)",
                    borderRadius: "20px",
                    padding: "2px 10px",
                    marginRight: "10px",
                    flexShrink: "0",
                  }}
                >
                  <FormControlLabel
                    id={type}
                    value={type}
                    sx={{
                      color: "#FFF",
                      fontFamily: "Manrope",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "normal",
                      textAlign: "center",
                    }}
                    control={
                      <Radio
                        sx={{
                          color: "#FFF",
                          "&.Mui-checked": { color: "#3CA465" },
                        }}
                      />
                    } // White radio button
                    label={type}
                  />
                </Box>
              ))}
            </Box>
          </RadioGroup>
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            marginTop="60px"
            flexDirection="column"
          >
            <Button
            variant="contained"
            sx={{
              bgcolor: "#3FA268",
              color: "white",
              padding: "12px 40px 12px 40px",
              textTransform: "none",
              fontSize: "16px",
              fontFamily: "Manrope",
              fontWeight: "700",
              borderRadius: "10px",
              "&:hover": {
                backgroundColor: "#3FA268",
              },
            }}
          >
            Next
          </Button>
            <Box
              sx={{
                mt: "0.2rem",
                display: "flex",
                height: "100%",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  color: "#3CA465",
                  fontFamily: "Poppins",
                  fontSize: "0.7rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%" /* 0.9375rem */,
                }}
              >
                Having troubles?
              </Typography>
              <Typography
                sx={{
                  paddingLeft: "0.5rem",
                  color: "#FFF",
                  fontFamily: "Poppins",
                  fontSize: "0.68rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "150%" /* 0.9375rem */,
                }}
              >
                Get Help
              </Typography>
            </Box>
          </Box>
        </Box>
      </Card>
    );
  };

  return <Onboarding Component={CreateIdentityCard} />;
};

export default IndustryIdentity;
