import React from "react";
import { Box, Typography, IconButton, Card, Grid } from "@mui/material";

const MetricCardContainer = ({ number, title, icon }) => {
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
    <Card
      sx={{
        minHeight: "0.625",
        borderRadius: "8px",
        border: "1px solid rgba(255, 255, 255, 0.13)",
        background: "rgba(255, 255, 255, 0.04)",
        backdropFilter: "blur(10.399999618530273px)",
      }}
    >
      <Box
        padding="13px"
        sx={{
          display: "flex",
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: "35px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "44px",
            }}
          >
            {number}
          </Typography>
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px",
            }}
          >
            {title}
          </Typography>
        </Box>

        <Box
          sx={{
            width: "auto",
            height: "auto",
            background: "#3FA268",
            borderRadius: "100px",
            padding: "10px 12px",
          }}
        >
          {icon}
        </Box>
      </Box>
    </Card>
    </Grid>
  );
};



export default MetricCardContainer;
