import React from "react";
import Navbar from "../../components/Navbar";
import {
  Box,
  Typography,
  Card,
  IconButton,
  TextField,
  Button,
} from "@mui/material";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import CircularProgress from "@mui/material/CircularProgress";
import InfoBar from "../../components/InfoBar";
import SubmitContactForm from "../../helper/submit_contact";

import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";

const ContactInfo = [
  {
    icon: <EmailIcon sx={{ color: "#FEFEFE" }} />,
    title: "shashank@reslink.org",
  },
  {
    icon: <AddIcCallIcon sx={{ color: "#FEFEFE" }} />,
    title: "+91 9560523752",
  },
  {
    icon: <LocationOnIcon sx={{ color: "#FEFEFE" }} />,
    title: "New Delhi, India",
  },
];

const SocialMedia = [
  {
    icon: <TwitterIcon sx={{ color: "#FEFEFE" }} />,
    title: "Twitter",
    link: "https://twitter.com/",
  },
  {
    icon: <LinkedInIcon sx={{ color: "#FEFEFE" }} />,
    title: "LinkedIn",
    link: "https://in.linkedin.com/company/reslink2023?trk=public_jobs_topcard_logo",
  },
  {
    icon: <InstagramIcon sx={{ color: "#FEFEFE" }} />,
    title: "Instagram",
    link: "https://www.instagram.com/reslinkorg/",
  },
];

const FormFieldNames = [
  {
    label: "First Name *",
    placeholder: "John",
    name: "first_name",
  },
  {
    label: "Last Name",
    placeholder: "Doe",
    name: "last_name",
  },
  {
    label: "Work Email *",
    placeholder: "example@workmail.com",
    name: "email",
  },
  {
    label: "Phone *",
    placeholder: "(include country code *)",
    name: "phone",
  },
];

const FormTextField = ({ placeholder, label, onChanged, name }) => {
  const [value, setValue] = React.useState("");
  const onChangeHandler = (e) => {
    setValue(e.target.value);
    onChanged(name, e.target.value);
  };
  return (
    <TextField
      onChange={onChangeHandler}
      value={value}
      id={label}
      label={label}
      placeholder={placeholder}
      variant="standard"
      InputProps={{
        style: { color: "#FFF" },
      }}
      InputLabelProps={{
        style: { color: "white", fontFamily: "Poppins" }, // Label color
      }}
      sx={{
        maxWidth: "auto",
        "& .MuiInputBase-input": {
          color: "#FEFEFE", // Text color
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "#8D8D8D",
          //change the width of the underline
          borderBottomWidth: "2px",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottomColor: "white", // Underline color when hovered and not focused
        },
        "& .MuiInput-underline.Mui-focused:before": {
          borderBottomColor: "white", // Underline color when focused
        },
        "& .MuiInput-underline.Mui-focused:after": {
          borderBottomColor: "#FEFEFE", // Underline color when focused
        },
      }}
    />
  );
};

const Contact = () => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [infoBarMessage, setInfoBarMessage] = React.useState("");

  const [data, setData] = React.useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    message: "",
    subject: "",
  });

  const onChangeHandler = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const clearForm = () => {
    setData({
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      message: "",
      subject: "",
    });
  };

  const handleSubmission = async () => {
    //TODO:implement handle submission
    console.log(data);
    setIsLoading(true);
    const response = await SubmitContactForm(data);
    setIsLoading(false);
    console.log("THE RESPONSE IS: ", response);
    setInfoBarMessage(response);
    setOpen(true);
    if (response.includes("successfully")) {
      clearForm();
    }
  };

  return (
    <Box
      sx={{
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowY: "auto",
        overflowX: "hidden",
        textAlign: "center",
      }}
    >
      <Navbar currentPage={"Contact"} />

      <Box
        sx={{
          // position: "absolute",
          // top: "50%",
          // left: "50%",
          // transform: {
          //   xs: "translate(-50%, -63%)",
          //   md: "translate(-50%, -50%)",
          // },
          textAlign: "left",
          width: "100%",
          marginTop: "120px",
          padding: { xs: "0px 0px", md: "0px 30px" },
          alignSelf: "center",

          //   bgcolor: "red",
          //   marginLeft: "2%",
        }}
      >
        <Box
          textAlign="center"
          width={{ xs: "93%", md: "73%" }}
          marginBottom="16px"
          marginLeft={{ xs: "3.5%", md: "11.9%" }}
        >
          <Box height="15px"></Box>
          <Card
            sx={{
              borderRadius: "20px",
              border: "1px solid rgba(255, 255, 255, 0.05)",
              background:
                "radial-gradient(50% 60% at 90% 90%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)",
              backdropFilter: "blur(3.5999999046325684px)",
              width: "100%",
              height: { xs: "auto", md: "auto" },
              // marginLeft: "5.5%",
            }}
          >
            <Box
              textAlign="center"
              width="100%"
              padding="20px"
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
            >
              <Box textAlign="center" width={{ xs: "90%", md: "50%" }}>
                <Card
                  sx={{
                    // height: "calc(100vh - 400px)",

                    borderRadius: "10px",
                    background: "var(--Primary-Black, #1F2129)",
                    padding: "30px 25px",
                    textAlign: "center",
                    height: "auto",
                  }}
                >
                  <Typography
                    sx={{
                      width: "100%",
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Gilroy-Bold",
                      fontSize: "25px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "normal" /* 129.948% */,
                      letterSpacing: " 1px",

                      textAlign: "left",
                    }}
                  >
                    Contact Information
                  </Typography>
                  <Typography
                    sx={{
                      width: "100%",
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Gilroy-Regular",
                      fontSize: " 18px",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "28.08px" /* 143.265% */,
                      letterSpacing: " 1px",

                      marginTop: "10px",
                      textAlign: "left",
                    }}
                  >
                    Get in Touch with Reslink.
                  </Typography>
                  <Box height="73px"></Box>
                  <Typography
                    sx={{
                      width: "100%",
                      color: "var(--Primary-White, #FEFEFE)",
                      fontFamily: "Poppins",
                      fontSize: " 18px",
                      fontStyle: "normal",
                      fontWeight: "600",
                      letterSpacing: " 1px",

                      marginTop: "10px",
                      textAlign: "left",
                    }}
                  >
                    Shashank Jha (CEO-Reslink)
                  </Typography>
                  <Box height="20px"></Box>
                  {ContactInfo.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "15px",
                        textAlign: "left",
                        marginBottom: "25px",
                      }}
                    >
                      {item.icon}
                      <Typography
                        sx={{
                          width: "100%",
                          color: "var(--Primary-White, #FEFEFE)",
                          fontFamily: "Gilroy-Regular",
                          fontSize: " 16px",
                          fontStyle: "normal",
                          fontWeight: "400",
                          lineHeight: "28.08px" /* 143.265% */,
                          letterSpacing: " 1px",

                          textAlign: "left",
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                  ))}
                  <Box height="63px"></Box>
                  <Box
                    display="flex"
                    textAlign="left"
                    padding="0px"
                    margin="0px"
                    marginLeft="-3px"
                    // marginTop="1px"
                  >
                    {SocialMedia.map((item, index) => (
                      <IconButton
                        onClick={() => window.open(item.link, "_blank")}
                        key={index}
                        sx={{
                          color: "#FEFEFE",
                          background: "var(--Primary-Black, #1F2129)",
                          borderRadius: "50px",
                          border: "2px solid rgba(255, 255, 255, 0.05)",
                          padding: "10px",
                          marginRight: "10px",
                          "&:hover": {
                            background: "#3FA268",
                          },
                        }}
                      >
                        {item.icon}
                      </IconButton>
                    ))}
                  </Box>
                </Card>
              </Box>

              {/* Contact Form */}
              <Box
                width={{ xs: "89%", md: "90%" }}
                height="100%"
                display="flex"
                flexDirection="column"
                textAlign="left"
                sx={{
                  padding: { xs: "0px", md: "0px 70px" },
                }}
                overflowY="scroll"
                marginTop="30px"
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 5,
                  }}
                >
                  {FormFieldNames.map((item, index) =>
                    index < 2 ? (
                      <FormTextField
                        key={index}
                        label={item.label}
                        placeholder={item.placeholder}
                        onChanged={onChangeHandler}
                        name={item.name}
                      />
                    ) : null
                  )}
                </Box>
                <Box height="55px"></Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: { sm: "1fr 1fr" },
                    gap: 5,
                  }}
                >
                  {FormFieldNames.map((item, index) =>
                    index >= 2 ? (
                      <FormTextField
                        key={index}
                        label={item.label}
                        placeholder={item.placeholder}
                        name={item.name}
                        onChanged={onChangeHandler}
                      />
                    ) : null
                  )}
                </Box>
                <Box height="55px"></Box>
                <Typography
                  sx={{
                    width: "100%",
                    color: "var(--Primary-White, #FEFEFE)",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "normal" /* 129.948% */,
                    letterSpacing: " 1px",

                    textAlign: "left",
                  }}
                >
                  Select Subject *
                </Typography>
                <Box height="10px"></Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="contactType"
                    name="contactType"
                    value={data.subject}
                    onChange={(e) => {
                      onChangeHandler("subject", e.target.value);
                    }}
                    sx={{
                      flexDirection: "row",
                      color: "white",
                      fontFamily: "Poppins",
                      justifyContent: "space-between",
                    }}
                    // Set text color to white and arrange in a row
                  >
                    <FormControlLabel
                      value="General Inquiry"
                      control={
                        <Radio
                          sx={{
                            color: "white",
                            "&.Mui-checked": {
                              color: "#3FA268", // Set the color of the selected dot to #3FA268
                            },
                          }}
                        />
                      }
                      label="General Inquiry"
                    />
                    <FormControlLabel
                      value="Complaint"
                      control={
                        <Radio
                          sx={{
                            color: "white", // Set unselected radio button color to white
                            "&.Mui-checked": {
                              color: "#3FA268", // Set the color of the selected dot to #3FA268
                            },
                          }}
                        />
                      }
                      label="Complaint"
                    />
                    <FormControlLabel
                      value="Feedback"
                      control={
                        <Radio
                          sx={{
                            color: "white", // Set unselected radio button color to white
                            "&.Mui-checked": {
                              color: "#3FA268", // Set the color of the selected dot to #3FA268
                            },
                          }}
                        />
                      }
                      label="Feedback"
                    />
                    <FormControlLabel
                      value="Other"
                      control={
                        <Radio
                          sx={{
                            color: "white", // Set unselected radio button color to white
                            "&.Mui-checked": {
                              color: "#3FA268", // Set the color of the selected dot to #3FA268
                            },
                          }}
                        />
                      }
                      label="Other&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    />
                  </RadioGroup>
                </FormControl>
                <Box height="45px"></Box>
                <TextField
                  value={data.message}
                  onChange={(e) => onChangeHandler("message", e.target.value)}
                  id="Message"
                  label="Message"
                  placeholder="Write a message"
                  variant="standard"
                  InputProps={{
                    style: { color: "#FFF" },
                  }}
                  InputLabelProps={{
                    style: { color: "white", fontFamily: "Poppins" }, // Label color
                  }}
                  sx={{
                    maxWidth: "auto",
                    "& .MuiInputBase-input": {
                      color: "white", // Text color
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#8D8D8D", // Underline color when not focused
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottomColor: "white", // Underline color when hovered and not focused
                    },
                    "& .MuiInput-underline.Mui-focused:before": {
                      borderBottomColor: "white", // Underline color when focused
                    },
                    "& .MuiInput-underline.Mui-focused:after": {
                      borderBottomColor: "white", // Underline color when focused
                    },
                  }}
                />
                <Box height="45px"></Box>
                <Box textAlign={{ xs: "center", md: "right" }}>
                  <Button
                    onClick={() => {
                      handleSubmission();
                    }}
                    variant="contained"
                    sx={{
                      bgcolor: "#3FA268",
                      color: "white",
                      padding: "12px 70px 12px 70px",
                      textTransform: "none",
                      fontSize: "16px",
                      fontFamily: "Manrope",
                      fontWeight: "700",
                      borderRadius: "10px",
                      width: { xs: "100%", md: "auto" },
                      "&:hover": {
                        backgroundColor: "#3FA268",
                      },
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress sx={{ color: "#FEFEFE" }} />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
      <InfoBar
        open={open}
        setOpen={setOpen}
        message={infoBarMessage}
        severity={infoBarMessage.includes("successfully") ? "success" : "error"}
      />
    </Box>
  );
};

export default Contact;
