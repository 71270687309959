import axios from "axios";
import baseURL from "../resources/BaseUrl";

async function createResearcherProfile(data) {
  console.log("Base URL is:", baseURL);

  console.log("Data for researcher is:", data);
  try {
    const endpoint = `${baseURL}/researcher/add_profile`;
    const response = await axios.post(endpoint, data);
    console.log("Response for researcher is:", response.data);
    localStorage.setItem("researcherToken", response.data.token);
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

export default createResearcherProfile;
