import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import AltNav from "../../components/AltNav";
import MetricCardContainer from "./components/MetricCard";
import Person from "../../assets/person.svg";
import Basil from "../../assets/basil.svg";
import Bookmark from "../../assets/bookmark.svg";
import Eye from "../../assets/carbon_view.svg";
import { Grid } from "@mui/material";
import ProjectsActivities from "./components/ProjectsActivities";
import CreateFirstProject from "./components/CreateFirstProject";
import MobNav from "../../components/MobNav";

const MetricData = [
  {
    number: "07",
    title: "Active Projects",
    icon: <img src={Person} alt="person" style={{ marginTop: "2px" }} />,
  },
  {
    number: "12",
    title: "Active Staff",
    icon: <img src={Basil} alt="person" style={{ marginTop: "2px" }} />,
  },
  {
    number: "02",
    title: "Active Clients",
    icon: <img src={Bookmark} alt="person" style={{ marginTop: "2px" }} />,
  },
  {
    number: "02",
    title: "Total Views",
    icon: <img src={Eye} alt="person" style={{ marginTop: "2px" }} />,
  },
];

const DashboardMain = ({ name }) => {
  return (
    // TODO: Making responsive has a bug with right margin.
    <Box
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        background:
          "radial-gradient(39.33% 60.33% at 50% 50%, #1F3B2A 20%, #272A31 100%)",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        display: "flex",
      }}
      height="100vh"
    >
      <Sidebar currentPage={"Dashboard"} />
      <Box
        width="75vw"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        padding={{ xs: "0px", md: "0px 20px" }}
      >
        <AltNav btn_text={"Create A Project"} />
        <MobNav />
        <Typography
          sx={{
            color: "var(--Primary-White, #FEFEFE)",
            fontFamily: "Poppins",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "38px",
          }}
        >
          Welcome back, Shashank
        </Typography>
        <Typography
          sx={{
            color: "var(--Gray-6, #F2F2F2)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "24px",
          }}
        >
          Track, manage researchers and submissions.
        </Typography>
        <Box height="32px"></Box>
        {/* Metric Cards */}
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            flexWrap: "wrap",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            {MetricData.map((metric, index) => (
              <MetricCardContainer
                key={index}
                number={metric.number}
                title={metric.title}
                icon={metric.icon}
              />
            ))}
          </Grid>
        </Box>
        <Box height="32px"></Box>

        <ProjectsActivities />
      </Box>
    </Box>
  );
};

export default DashboardMain;
