import React from "react";
import Navbar from "../../../components/Navbar";
import {
  Box,
  Typography,
  Card,
  IconButton,
  TextField,
  Button,
  Switch,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import PriceCard from "./PriceCard";
import CardWithBigButtonPricing from "./CardWithBigButtonPricing";
import PricingSponsorIcons from "./PricingSponsorIcons";
import SingleTestimonial from "./SingleTestimonial";
import "./PriceCardDiv.css";
import BottomIcons from "../../Landing_Page/components/BottomIcons";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 27,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translate(25px,0px)", // Position when the switch is checked
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#3FA268" : "#3FA268",
        opacity: 1,
        border: 0,
      },
    },
    // Position when the switch is unchecked (start position)
    transform: "translate(0,0px)",
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#3FA268" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));



const PriceCardDataMonthly = [
  {
    industry_type: "Startup",
    points: [
      "Upto 5 successful R&D collaborations",
      "5-10% Success Fee",
      "Ai-driven milestone based tracking of entire project",
      "Access to labs, chemicals, equipments",
      "Upto 10 Research Hiring",
    ],
    price: "499",
    popular: false,
  },
  {
    industry_type: "Enterprise",
    points: [
      "All Startup features",
      "1-5% Success Fee",
      "Upto 10 successful R&D collaborations",
      "Upto 20 research hirings",
      "Custom reporting and analytics",
    ],
    price: "999",
    popular: false,

  },
  {
    industry_type: "Corporation",
    points: [
      "All Enterprise features",
      "0-3% Success Fee",
      "Unlimited successful R&D collaborations",
      "Unlimited research hirings",
      "Access to groundbreaking patents",
    ],
    price: "1999",
    popular: true,

  },
];

const PriceCardDataAnnually = [
  {
    industry_type: "Startup",
    points: [
      "Upto 5 successful R&D collaborations",
      "5-10% Success Fee",
      "Ai-driven milestone based tracking of entire project",
      "Access to labs, chemicals, equipments",
      "Upto 10 Research Hiring",
    ],
    price: "4999",
    popular: false,

  },
  {
    industry_type: "Enterprise",
    points: [
      "All Startup features",
      "1-5% Success Fee",
      "Upto 10 successful R&D collaborations",
      "Upto 20 research hirings",
      "Custom reporting and analytics",
    ],
    price: "8999",
    popular: false,

  },
  {
    industry_type: "Corporation",
    points: [
      "All Enterprise features",
      "0-3% Success Fee",
      "Unlimited successful R&D collaborations",
      "Unlimited research hirings",
      "Access to groundbreaking patents",
    ],
    price: "16999",
    popular: true,

  },
];

const PricingCommon = () => {

  const [switchState,  setSwitchState] = React.useState(false);

  return (
    <Box
      sx={{
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        height: "auto",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflowY: "auto",
        overflowX: "hidden",
        textAlign: "center",
      }}
    >
      <Navbar currentPage={"Pricing"} />
      <Box
        sx={{
          textAlign: "center",
          //   width: "100%",
          marginTop: "180px",
          marginLeft: "auto",
          marginRight: "auto",
          padding: { xs: "0px 0px", md: "0px 0px" },
          // alignSelf: "center",

          // bgcolor: "red",
          //   marginLeft: "2%",
        }}
      >
        <Box
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            // alignSelf: "center",
          }}
        >
          <Typography
            sx={{
              color: "#3FA268",
              fontFamily: "Gilroy-Bold",
              fontSize: " 18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "28.08px" /* 143.265% */,
              letterSpacing: "-0.432px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "10px",
            }}
          >
            PRICING
          </Typography>
          <Typography
            sx={{
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Bold",
              fontSize: " 43px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "72px" /* 143.265% */,
              letterSpacing: "1px",
              marginTop: "10px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Our pricing plans
          </Typography>
          <Typography
            sx={{
              width: {xs:"90%",md:"40%"},
              marginLeft: "auto",
              marginRight: "auto",
              color: "var(--Primary-White, #FEFEFE)",
              fontFamily: "Gilroy-Regular",
              fontSize: " 18px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "30px" /* 143.265% */,
              letterSpacing: "1px",
              marginTop: "10px",
            }}
          >
            Choose the pricing plan that best meets your needs and budget, and
            start accelerating your R&D today.
          </Typography>
          <Box height="40px"></Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              marginLeft: {xs:"0px",md:"90px"},
              marginRight: "auto",
            }}
          >
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                letterSpacing: "1px",
                marginRight: "30px",
              }}
            >
              Billed Monthly
            </Typography>
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} />}
              value={switchState}
              onChange={() => {
                setSwitchState(!switchState);
                console.log(switchState);
              }}
            />
            <Typography
              sx={{
                display: { xs: "none", md: "block" },
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                letterSpacing: "1px",
              }}
            >
              Billed Annually{" "}
              <span style={{ color: "#3FA268" }}>&nbsp;(save 20%)</span>
            </Typography>
            <Typography
              sx={{
                display: { xs: "block", md: "none" },
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 18px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "30px" /* 143.265% */,
                letterSpacing: " 1px",
              }}
            >
              Billed Annually{" "}<br/>
              <span style={{ color: "#3FA268" }}>&nbsp;(save 20%)</span>
            </Typography>
          </Box>
        </Box>
        <Box height="80px"></Box>
        <div className="myDiv">  
          {
            switchState ? (
             <>
                {PriceCardDataAnnually.map((item) => {
                  return (
                    <PriceCard
                      industry_type={item.industry_type}
                      points={item.points}
                      price={item.price}
                      billing="Annually"
                      popular={item.popular}
                    />
                  );
                })}
             </>
            ) : (
             <>
                {PriceCardDataMonthly.map((item) => {
                  return (
                    <PriceCard
                      industry_type={item.industry_type}
                      points={item.points}
                      price={item.price}
                      billing="Monthly"
                      popular={item.popular}

                    />
                  );
                })}
              </>
            )
          }
        </div>
        <Box height="34px"></Box>
        <CardWithBigButtonPricing />
        <Box height="200px"></Box>
        {/* <PricingSponsorIcons /> */}
        <BottomIcons/>
        <Box height="70px"></Box>
        {/* <SingleTestimonial /> */}
      </Box>
    </Box>
  );
};

export default PricingCommon;
