import React from "react";
import { Box, Typography, Card, Grid } from "@mui/material";
import { FeaturedServicesCards } from "../../Landing_Page/components/FeaturedServicesCards";
import gumdrop from "../../../assets/about_common_feature_cards/gumdrop.png";
import increaseApple from "../../../assets/about_common_feature_cards/increaseApple.png";
import ngo from "../../../assets/about_common_feature_cards/ngo.png";
import CheckIcon from "@mui/icons-material/Check";
import tableTeam from "../../../assets/about_common_feature_cards/table_team.png";
import EastIcon from "@mui/icons-material/East";
import CardWithBigButton from "../../Landing_Page/components/CardWithBigButton";

const AboutCommon = () => {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "auto",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        // padding: "20px 20px 20px 20px",
        // bgcolor: "red",
        textAlign: "center",
        marginTop: "0px",
      }}
    >
      <Box
        width="100%"
        textAlign="center"
        sx={{
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Card
          sx={{
            borderRadius: "20px",
            border: "1px solid rgba(255, 255, 255, 0.05)",
            background:
              "radial-gradient(50% 50% at 50% 50%, rgba(0, 255, 101, 0.12) 0%, rgba(255, 255, 255, 0.04) 100%)",
            backdropFilter: "blur(3.5999999046325684px)",
            maxWidth: { xs: "93%", md: "90%" },
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box
            textAlign="center"
            width="100%"
            padding={{ xs: "50px 0px", md: "auto" }}
            sx={{
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Bold",
                fontSize: { xs: "1.875rem", md: "50px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "75px" /* 129.948% */,
                letterSpacing: "1px",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              Our Values
            </Typography>
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: { xs: "0.875rem", md: " 18px" },
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: { xs: "normal", md: "28.08px" } /* 143.265% */,
                letterSpacing: " 1px",

                padding: { xs: "0px 20px", md: "none" },
              }}
            >
              At Reslink, our values drive us to empower innovation through
              collaboration.
            </Typography>
            <Box height="20px"></Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 4,
                marginTop: "50px",
                width: "100%",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <FeaturedServicesCards
                icon={gumdrop}
                title="Innovation"
                subtext=" We champion innovation, fostering an environment where groundbreaking ideas flourish, leading to real-world impact."
              />
              <FeaturedServicesCards
                icon={ngo}
                title="Collaboration"
                subtext="Collaboration is at our core, as we believe that diverse minds working together yield superior solutions and drive positive change."
              />
              <FeaturedServicesCards
                icon={increaseApple}
                title="Integrity"
                subtext="We uphold the highest standards of integrity, ensuring transparency, trust, and ethical conduct in all our partnerships and interactions."
              />
            </Box>
          </Box>
        </Card>
      </Box>

      <Box height={{ xs: "100px", md: "173px" }}></Box>

      <Grid
        container
        spacing={{ xs: 0, md: "auto" }}
        justifyContent="center"
        alignItems="center"
        height="100%"
        display="flex"
        sx={{
          flexDirection: "row-reverse",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <Grid
          // bgcolor="blue"
          item
          md={6}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            padding: { xs: "0px 20px", md: "0px 0px" },
          }}
        >
          <Card
            sx={{
              // marginLeft: "15%",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.05)",
              background: "rgba(255, 255, 255, 0.03)",
              backdropFilter: "blur(3.5999999046325684px)",
              maxWidth: { xs: "93%", md: "50%" },
              textAlign: "center",
              padding: "40px 20px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <img src={tableTeam} height="100%" width="100%" />
          </Card>
        </Grid>
        <Grid
          item
          md={6}
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
          }}
          // bgcolor="red"
        >
          <Box
            // textAlign="center"
            marginTop={{ xs: "20px", md: "0px" }}
            width={{ xs: "90%", md: "70%" }}
            sx={{
              marginLeft: { xs: "auto", md: "30%" },
              marginRight: { xs: "auto", md: "30%" },
            }}
          >
            <Typography
              sx={{
                color: "var(--Primary-White, #3FA268)",
                fontFamily: "Gilroy-Bold",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal" /* 129.948% */,
                letterSpacing: "0.4px",
                textAlign: "left",
                marginBottom: "20px",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              Join Us
              <span style={{ color: "#FEFEFE" }}>
                &nbsp;in Shaping Tomorrow
              </span>
            </Typography>
            <Typography
              sx={{
                textAlign: "left",
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Gilroy-Regular",
                fontSize: " 16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.4px" /* 143.265% */,
                letterSpacing: " 1px",
                marginBottom: "20px",
              }}
            >
              Whether you're a researcher with a groundbreaking idea or an
              industry professional seeking fresh perspectives, Reslink is your
              destination for forging meaningful partnerships. Together, we can
              create a brighter tomorrow—one breakthrough at a time.
            </Typography>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#3FA268",
                fontFamily: "Gilroy-Bold",
                fontSize: " 16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "26.4px" /* 143.265% */,
                letterSpacing: " 1px",

                marginBottom: "20px",
              }}
            >
              SEE OPEN POSITIONS
              <EastIcon
                sx={{ color: "#3FA268", fontSize: "16px", marginLeft: "10px" }}
              />
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box height="117px"></Box>
      <CardWithBigButton
        title={"Accelerate your R&D with Reslink. Join us today!"}
        subtext={
          "Bridging the Gap Between Researchers and Industries for a Better Tomorrow. "
        }
      />
      <Box height="46px"></Box>
    </Box>
  );
};

export default AboutCommon;
