import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Box } from '@mui/material';

function OTPInput() {
  const [otp, setOTP] = useState(['', '', '', '', '', '']); // An array to store OTP digits

  const handleChange = (e, index) => {
    const { value } = e.target;
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);
  };

  return (
    <Box
        sx={{
            display: 'flex',
            // backgroundColor: 'red',
            justifyContent: 'space-between',
            flexDirection: 'row',
            width: '100%',
            mb: '1rem',
            mt: '1rem',
        }}
    >
      {otp.map((digit, index) => (
        <TextField
          key={index}
          variant="outlined"
          // size="large"
          type="text"
          value={digit}
          onChange={(e) => handleChange(e, index)}
          inputProps={{
            maxLength: 1,
            style: { 
                height: "35px",
                width: "38px",
                textAlign: 'center',
                borderRadius: "0.625rem",
                border : "1px solid rgba(255, 255, 255, 0.25)",
                color: "#FFFFFF",
                fontSize: "1.5rem",
                 },
          }}
        />
      ))}
    </Box>
  );
}

export default OTPInput;
