import React from "react";
import { Box, Typography } from "@mui/material";
import { IconArray } from "../../../resources/Icons";
import all_logos from "../../../assets/icon_images/all_logo.png";


const AboutBottomIcons = () => {
  return (
    <Box
      zIndex={1}
      display="flex"
      flexDirection="column"
      justifyContent={{ xs: "center", md: "space-between" }}
      sx={{
        position: { md: "absolute" },
        bottom: { md: "5px" },
        left: { md: "0" },
        width: "100%",
        background: "#1F2129",
        backdropFilter: "blur(2.799999952316284px)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100vw",
        height: "auto",
      }}
      textAlign="center"
      marginLeft={{ md: "1.5%" }}
      paddingTop={{ xs: "50px", md: "0px" }}
      marginBottom={{ xs: "55px", md: "20px" }}
    >
      <Typography
        sx={{
          color: "var(--Gray-6, #F2F2F2)",
          textAlign: "center",
          fontFamily: "Gilroy-Bold",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "15px" /* 107.143% */,
          letterSpacing: "0.4px",
          textTransform: "uppercase",
        }}
      >
        OUR PARTNERS
      </Typography>
      <Box
        sx={{
          padding: { xs: "5px", md: "10px" },
          display: { xs: "none", md: "flex" },
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
          marginTop: { xs: "0px", md: "20px" },
        }}
      >
        {IconArray.map((item, index) => {
          return (
            <img
              src={item.image}
              alt={item.name}
              key={index}
              style={{ height: "60px", width: "105px", marginRight: "15px" }}
            />
          );
        })}
      </Box>
      <Box>
        <Box
          sx={{
            padding: "8px 0px",
            display: { xs: "flex", md: "none" },
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: { xs: "15px", md: "20px" },
            gap: "13px",
          }}
        >
          {IconArray.map((item, index) => {
            if (index === 6 || index === 5 || index === 3)
              return (
                <img
                  src={item.image}
                  alt={item.name}
                  key={index}
                  style={{ height: "56px", width: "84px", marginRight: "15px" }}
                />
              );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default AboutBottomIcons;
