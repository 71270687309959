import { Box, Typography } from "@mui/material";
import React from "react";
import dots from "../../../assets/dots-horizontal.png";
import link from "../../../assets/link-02.svg";
import verifiedProfile from "../../../assets/verifiedProfile.png";
import mail from "../../../assets/mail-01.svg";

function Header({ profile }) {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // bgcolor: "red",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <img
          src={profile?.profile_image || verifiedProfile}
          style={{
            marginTop: "-60px",
            borderRadius: "50%",
            width: "200px",
            height: "200px",
            border: "5px solid var(--Base-White, #FFF)",
          }}
        />
        <Box>
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "38px",
            }}
          >
            {profile?.name || "No Name"}
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            I’m a Battery Research Expert
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <button
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            borderRadius: "8px",
            padding: "10px 14px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <img src={dots} alt="dots" />
        </button>
        <button
          onClick={() => {
            var currentURL = window.location.href;
            navigator.clipboard.writeText(currentURL);
            alert("Link copied to clipboard.");
          }}
          style={{
            display: "flex",
            padding: "10px 14px",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            borderRadius: "8px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <img src={link} alt="link" />
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Share
          </Typography>
        </button>
        <button
          onClick={() => {
            console.log("clicked", profile?.email);
            console.log(window.location.href);
            window.location.href = `mailto:${profile?.email}`;
          }}
          style={{
            display: "flex",
            padding: "10px 14px",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            borderRadius: "8px",
            border: "1px solid var(--Brand-600, #099250)",
            background: "var(--Brand-600, #099250)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          <img src={mail} alt="link" />
          <Typography
            sx={{
              color: "var(--Base-White, #FFF)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            Get In Touch
          </Typography>
        </button>
      </Box>
    </Box>
  );
}

export default Header;
