import { Box, Typography } from "@mui/material";
import React from "react";
import "../Signup.css";

function ResearcherModalBox({
  icon,
  title,
  index,
  handleSelection,
  className,
  selected,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        border: "1px solid var(--Gray-200, #EAECF0)",
        background: "var(--Base-White, #FFF)",
        padding: "10px",
        gap: "10px",
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <img src={icon} alt="" />
          <Typography>{title}</Typography>
        </Box>
        <div className="new-circle">
          <input
            type="radio"
            name="selection" // Ensure all radio buttons have the same `name`
            id={className} // Ensure the ID is unique for each option
            checked={selected === index} // Check the radio button if it matches the current selection
            onChange={() => handleSelection(index)} // Use `onChange` for radio buttons
          />
          <label htmlFor={className}></label>
        </div>
      </Box>
    </Box>
  );
}

export default ResearcherModalBox;
