import React from "react";
import Footer from "../../components/Footer";
import { Box } from "@mui/material";
import FeaturesHero from "./components/FeaturesHero";
import FeaturesCommon from "./components/FeaturesCommon";

const Features = () => {
  return (
    <Box sx={{ overflowY: "scroll", overflowX: "hidden" }} height="100vh">
        <FeaturesHero />
        <FeaturesCommon />
        <Footer />
    </Box>
  );
};

export default Features;
