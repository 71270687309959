import React from "react";
import { Box, Card, Typography } from "@mui/material";
import BackgroundComp from "./components/background_comp";
import SidePanel from "./components/side_panel";
import stars_icon from "../../assets/stars.png";
import InputField from "./components/input_field";
import "./SearchBox.css";
import updateResearcherProfile from "../../helper/updateResearcherProfile";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import bucketUploader from "../../helper/bucketUploader";

const FinalComponent = () => {
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    profile_title: "",
    profile_about: "",
    profile_image: "",
    profile_banner_image: "",
    stage: 3,
  });

  const [loading, setLoading] = React.useState(false);

  const handleContinue = async () => {
    if (data.profile_title === "") {
      alert("Please add profile title");
      return;
    }
    if (data.profile_about === "") {
      alert("Please add profile about");
      return;
    }
    if (data.profile_image === "") {
      alert("Please add a profile image");
      return;
    }
    if (data.profile_banner_image === "") {
      alert("Please add a banner image");
      return;
    }
    setLoading(true);
    console.log("Data", data);
    try {
      const profile_pic_upload_response = await bucketUploader({
        fileInput: document.getElementById("profile_image").files[0],
        tag: "profile_image",
      });
      console.log("Profile pic link", profile_pic_upload_response);
      const banner_pic_upload_response = await bucketUploader({
        fileInput: document.getElementById("banner_image").files[0],
        tag: "banner_image",
      });
      console.log("Banner pic link", banner_pic_upload_response);

      let modifiedData = { ...data };
      modifiedData.profile_image = profile_pic_upload_response;
      modifiedData.profile_banner_image = banner_pic_upload_response;

      // setData({ ...data, profile_image:profile_pic_upload_response});

      try {
        const response = await updateResearcherProfile(modifiedData);
        console.log(response);
        navigate("/login");
      } catch (e) {
        alert("Error updating profile");
        console.log(e);
      }
    } catch (e) {
      console.log(e);
      alert("Error uploading images");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        // bgcolor: "red",
      }}
    >
      <Card
        elevation="0"
        width="52px"
        height="52px"
        sx={{
          padding: "12px 14px",
          borderRadius: "10px",
          border: "1px solid var(--Gray-200, #EAECF0)",
        }}
      >
        <img src={stars_icon} alt="icon" />
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "360px",
          boxShadow: "none",
          bgcolor: "transparent",
          gap: "24px",
          border: "none",
          // overflow: "scroll",
          // height: "20rem",
        }}
      >
        {/* <img src={logo} alt="" /> */}
        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "30px",
          }}
        >
          Finish Profile
        </Typography>
        <Typography
          sx={{
            color: "var(--Gray-600, #475467)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 400,
            // lineHeight: "26px",
          }}
        >
          Just a few more details to complete your profile
        </Typography>
        <InputField
          label="Profile Title"
          placeholder="Enter your profile title"
          required={true}
          value={data.profile_title}
          onChange={(e) => {
            setData({ ...data, profile_title: e.target.value });
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            About*
          </Typography>
          <textarea
            rows={4}
            style={{
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              padding: "12px 14px",
              width: "90%",
              height: "100px",
              resize: "none",
            }}
            placeholder="Enter your profile about"
            value={data.profile_about}
            onChange={(e) => {
              setData({ ...data, profile_about: e.target.value });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Upload Profile Image*
          </Typography>
          <input
            id="profile_image"
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            style={{
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              padding: "12px 14px",
              width: "90%",
            }}
            placeholder="Enter your profile image"
            value={data.profile_image}
            onChange={(e) => {
              setData({ ...data, profile_image: e.target.value });
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Upload Profile Banner Image*
          </Typography>
          <input
            id="banner_image"
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            style={{
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              padding: "12px 14px",
              width: "90%",
            }}
            placeholder="Enter your profile banner image"
            value={data.profile_banner_image}
            onChange={(e) => {
              setData({ ...data, profile_banner_image: e.target.value });
            }}
          />
        </Box>
        <button
          onClick={handleContinue}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Brand-600, #099250)",
            background: "var(--Brand-600, #099250)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "#FEFEFE" }} />
          ) : (
            <Typography
              sx={{
                color: "var(--Base-White, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Continue
            </Typography>
          )}
        </button>
      </Card>
    </Box>
  );
};

const FinalizeProfile = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
      }}
    >
      <SidePanel selectedIndex={3} />
      <BackgroundComp Component={FinalComponent} />
    </Box>
  );
};

export default FinalizeProfile;
