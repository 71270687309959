import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/Background_pattern_decorative.png";
import bg_mb from "../../assets/Background_pattern_decorative_mb.png";
import google from "../../assets/google.png";
import logo from "../../assets/logo.png";
import "./Login.css";
import LoginResearcher from "../../helper/loginResearcher";
import { CircularProgress } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const label = { "aria-label": "Checkbox demo" };

const urlList = [
  "/researcher/profile-details",
  "/researcher/research-and-exp",
  "/researcher/research-projects",
  "/researcher/finalize-profile",
  "/researcher/profile",
];

const LoginMain = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState("researcher");
  const [data, setData] = React.useState({
    email: "",
    password: "",
  });

  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log("Code Response", codeResponse);
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse?.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse?.access_token}`,
              Accept: "application/json",
            },
          }
        );
        console.log("Google Response", res.data);
        // setData({
        //  email: res.data.email,
        //   password: res.data.id,
        // });
        console.log("Data", data);
        if (radioValue === "researcher") {
          const resp = await LoginResearcher({
            email: res.data.email,
            password: res.data.id,
          });
          console.log("Response", resp);
          if (resp.stage < 3) {
            navigate(urlList[resp.stage + 1]);
          } else {
            navigate(`/researcher/profile?uuid=${resp.profile.uuid}`);
          }
        } else {
          // industry login
        }
      } catch (err) {
        setData({ email: "", password: "" });

        console.log("Error", err.message);
        alert("Google login failed");
      }
    },
    onError: () => {
      // Handle login errors here
      alert("Google login failed");
      console.error("Google login failed");
    },
    // flow: "auth-code",
  });

  async function handleContinue() {
    if (data.email === "") {
      alert("Please enter your email");
      return;
    }
    if (data.password === "") {
      alert("Please enter your password");
      return;
    }
    console.log("Data", data);
    try {
      setLoading(true);
      const resp = await LoginResearcher(data);
      console.log("Response resp: ", resp);
      if (resp.stage < 3) {
        console.log("Entering stage < 3");
        navigate(urlList[resp.stage + 1]);
      } else if (resp.stage >= 3) {
        console.log("UUID at login", resp.profile.uuid);
        navigate(`/researcher/profile?uuid=${resp.profile.uuid}`);
      }
    } catch (error) {
      if (error.message === "Request failed with status code 404") {
        alert("User not found");
      }
      if (error.message === "Request failed with status code 403") {
        alert("Invalid credentials");
      }
      if (error.message === "Request failed with status code 401") {
        alert("Invalid credentials");
      } else {
        alert(error.message);
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: "block",
        // flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
        overflowX: "hidden",
        height: "100vh",
        // bgcolor: "red",
        backgroundImage: `url(${bg})`,
        backgroundSize: { xs: "150%", md: "50%" },
        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
        // padding: { xs: "50px", md: "0px" },
      }}
    >
      {/* <img src={bg} alt="Background" className="bg-desktop" />
      <img src={bg_mb} alt="Background Mobile" className="bg-mobile" /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // position: "absolute",
          // top: "10%",
          padding: { xs: "20px 26px", md: "20px 24px" },
          // height: "100vh",
          overflowY: "auto",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "360px",
            boxShadow: "none",
            bgcolor: "transparent",
            gap: "24px",
            border: "none",
          }}
        >
          <img src={logo} alt="" />
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "38px",
            }}
          >
            Log in to your account
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Welcome back! Please enter your details.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Who are you?
            </Typography>
            <RadioGroup
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="researcher"
                control={
                  <Radio
                    color="primary"
                    sx={{
                      color: "var(--Brand-600, #099250)", // Change to your desired color
                      "&.Mui-checked": {
                        color: "var(--Brand-600, #099250)", // Change to your desired color
                      },
                    }}
                  />
                }
                label="Researcher"
                labelPlacement="end"
              />
              <FormControlLabel
                value="industry"
                control={
                  <Radio
                    color="primary"
                    sx={{
                      color: "var(--Brand-600, #099250)", // Change to your desired color
                      "&.Mui-checked": {
                        color: "var(--Brand-600, #099250)", // Change to your desired color
                      },
                    }}
                  />
                }
                label="Industry"
                labelPlacement="end"
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Email
            </Typography>
            <input
              value={data.email}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                display: "flex",
                padding: "10px 14px",
                alignItems: "center",
                gap: "8px",
                width: "90%",
              }}
              placeholder="Enter your email"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Password
            </Typography>
            <input
              value={data.password}
              onChange={(e) => {
                setData({ ...data, password: e.target.value });
              }}
              type="password"
              style={{
                borderRadius: "8px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                display: "flex",
                padding: "10px 14px",
                alignItems: "center",
                gap: "8px",
                width: "90%",
              }}
              placeholder="Enter your password"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <input type="checkbox" {...label} />
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Remember for 30 days
              </Typography>
            </Box> */}
            <Typography
              onClick={() => {
                navigate("/forgot-password");
              }}
              sx={{
                color: "var(--Brand-600, #099250)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              Forgot password?
            </Typography>
          </Box>
          <button
            onClick={handleContinue}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Sign in
              </Typography>
            )}
          </button>
          <button
            onClick={googleLogin}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            <img
              src={google}
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Sign in with Google
            </Typography>
          </button>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Don't have an account?
            </Typography>
            <Typography
              onClick={() => {
                navigate("/signup");
              }}
              sx={{
                color: "var(--Brand-700, #087443)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              Sign up
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default LoginMain;
