import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

export default function VerticalLinearStepper({ steps }) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [finishSelection, setFinishSelection] = React.useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box
      sx={{
        maxWidth: "55%",
        borderRadius: 3,
        border: "1px solid rgba(255, 255, 255, 0.25)",
        padding: "20px",
        marginLeft: "-2px",
      }}
    >
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ maxWidth: "80%" }}
      >
        {steps.map((step, index) => (
          <Step key={step.label} id={step.label}>
            <StepLabel
              sx={{
                ".MuiStepLabel-label": {
                  color: activeStep===index?"#FFF !important":"#FFF" ,
                  fontSize: "18px",
                  fontWeight: activeStep == index ? "600 !important" : "500",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  fontFamily: "Poppins",
                },
                // " .Mui-active": {
                //   color: "#3DB46C",
                //   fontSize: "18px",
                //   fontWeight: activeStep == index ? "600" : "500",
                //   fontStyle: "normal",
                //   lineHeight: "24px",
                //   fontFamily: "Poppins",
                // },
              }}
              StepIconProps={{
                style: {
                  color:
                    activeStep >= index
                      ? "#3DB46C "
                      : "rgba(255, 255, 255, 0.25)",
                },
              }}
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Typography sx={{ color: "#FFF" }}>{step.description}</Typography>
              <Box sx={{ mb: 2 }}>
                <div>
                  <Button
                    disabled={finishSelection}
                    variant="contained"
                    onClick={handleNext}
                    sx={{
                      mt: 1,
                      mr: 1,
                      bgcolor: "#3DB46C",
                      "&:hover": {
                        background: "#07B54B",
                      },
                    }}
                  >
                    Add
                  </Button>
                  <Button
                    disabled={index === 0 || finishSelection}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1, color: "#FFF" }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {(finishSelection || activeStep === steps.length) && (
        <Paper
          square
          elevation={0}
          sx={{
            p: 3,
            borderRadius: "10px",
            marginTop: "10px",
            marginBottom: "5px",
          }}
        >
          <Typography>Your response is recorded</Typography>
          <Button
            onClick={() => {
              setFinishSelection(false);
              handleReset();
            }}
            sx={{ mt: 1, mr: 1 }}
          >
            Reset
          </Button>
        </Paper>
      )}
      <Box width="100%" textAlign="right">
        <Button
          variant="contained"
          onClick={() => {
            setFinishSelection(true);
          }}
          sx={{
            mt: 1,
            mr: 1,
            bgcolor: "#3DB46C",
            "&:hover": {
              background: "#07B54B",
            },
          }}
        >
          Done
        </Button>
      </Box>
    </Box>
  );
}
