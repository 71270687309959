import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import image from "../assets/onboarding_vector.svg";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import ProfileIcon from "../assets/profile_icon.svg";

const BackgroundScreen = ({ Component, Title }) => {
  return (
    <Box
      sx={{
        background:
          "radial-gradient(71.33% 72.1% at 0% 89%, #1F3B2A 0%, #111418 100%);",
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",

      }}
    >
      <Box
        display="flex"
        gap={5}
        alignItems='center'
        justifyContent='flex-start'
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          padding: "15px 18px",
        }}
      >
        <img src={image} alt="logo_img" width="61px" height="47px" />
        <Typography
          sx={{
            color: "white",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "24px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "54px",
          }}
        >
          {Title}
        </Typography>
      </Box>

      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "0",
          padding: "10px 5px 0px 0px",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "-2px",
        }}
      >
        <IconButton
          sx={{ color: "#3FA268", display: "flex", flexDirection: "column" }}
        >
          {/* <PermIdentityIcon fontSize="large" /> */}
          <img
            src={ProfileIcon}
            alt="profile_icon"
            width="18"
            height="20"
            style={{ marginBottom: "10px" }}
          />
          <Typography
            sx={{
              color: "#3FA268",
              textAlign: "center",
              fontFamily: "Manrope",
            }}
          >
            Name
          </Typography>
        </IconButton>
      </Box>

      <Component />
    </Box>
  );
};

export default BackgroundScreen;
