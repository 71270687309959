import React from "react";
import { Box, Typography, IconButton, Divider, Grid } from "@mui/material";
import logo from "../assets/alt_nav_logo.svg";
import olivia from "../assets/olivia.png";
import shashank from "../assets/shashank.png";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import LayersOutlinedIcon from "@mui/icons-material/LayersOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router-dom";

const pages = ["Dashboard", "Projects", "Staffing", "Messages"];

const Sidebar = ({ currentPage }) => {
  const navigate = useNavigate();

  const handleNavClick = (page) => {
    navigate(`/${page.toLowerCase()}`);
  };

  return (
    <Box
      sx={{
        display: { xs: "none", md: "flex" },
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "stretch",
        height: "100vh",
        width: "20vw",
        background: "transparent",
        padding: "20px 10px",
        borderRight: "1px solid rgba(255, 255, 255, 0.13)",
      }}
    >
      <Box sx={{ padding: "0px", marginLeft: "15px" }}>
        <img
          onClick={() => handleNavClick("Home")}
          src={logo}
          alt="logo"
          style={{
            height: "auto",
            width: "173.742px",
            cursor: "pointer",
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "stretch",
          height: "80%",
          width: "20vw",
          background: "transparent",
          padding: "20px 10px",
          marginTop: "23px",
          //   borderRight: "1px solid rgba(255, 255, 255, 0.13)",
          //   bgcolor:"red"
        }}
      >
        <Box>
          <Grid container direction="column" spacing={1}>
            {pages.map((page, index) => (
              <Grid item key={index}>
                <IconButton
                  onClick={() => handleNavClick(page)}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    color: "#fff",
                    background:
                      currentPage === page ? "#101215" : "transparent",
                    borderRadius: "10px",
                    padding: "10px 18px",
                    width: "90%",
                    "&:hover": {
                      background: "#fff",
                      color: "#000",
                    },
                  }}
                >
                  {page === "Dashboard" && <LeaderboardOutlinedIcon />}
                  {page === "Projects" && <LayersOutlinedIcon />}
                  {page === "Staffing" && <PeopleAltOutlinedIcon />}
                  {page === "Messages" && <MailOutlineIcon />}
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      lineHeight: "24px",
                      marginLeft: "10px",
                    }}
                  >
                    {page}
                  </Typography>
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <IconButton
                // onClick={() => handleNavClick(page)}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  color: "#fff",
                  background: "transparent",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  width: "100%",
                  "&:hover": {
                    background: "#fff",
                    color: "#000",
                  },
                }}
              >
                <HelpOutlineOutlinedIcon />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    marginLeft: "10px",
                  }}
                >
                  Support
                </Typography>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                // onClick={() => handleNavClick(page)}
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  color: "#fff",
                  background: "transparent",
                  borderRadius: "10px",
                  padding: "10px 20px",
                  width: "100%",
                  "&:hover": {
                    background: "#fff",
                    color: "#000",
                  },
                }}
              >
                <SettingsOutlinedIcon />
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "24px",
                    marginLeft: "10px",
                  }}
                >
                  Settings
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box>
        <Divider
          sx={{
            background: "rgba(255, 255, 255, 0.1)",
            borderBottomWidth: 2,
          }}
        />

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "20px 20px 0px 10px",
            marginBottom: "30px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img
              src={shashank}
              alt="User Profile"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                marginRight: "10px",
              }}
            />
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
              }}
            >
              Shashank Jha
            </Typography>
          </Box>
          <IconButton
            sx={{
              color: "#fff",
              background: "transparent",
              "&:hover": {
                background: "#fff",
                color: "#000",
              },
            }}
          >
            <ExitToAppIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default Sidebar;
