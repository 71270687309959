import React from "react";
import BackgroundScreen from "../components/BackgroundScreen";
import { Box, Card } from "@mui/material";
import ProgressBar from "../components/ProgressBar";
import { StepList } from "../resources/StepList";
import LargeTextSmallText from "../components/LargeTextSmallText";
import AddMore from "../components/AddMore";
import BackContinueButtons from "../components/BackContinueButtons";
import { useNavigate } from "react-router-dom";
import RNDTextField from "../components/RNDTextField";

const RNDBg = () => {
  const navigate = useNavigate();
  const handleContinue = () => {
    navigate("/create-new-rnd/requirements");
  };
  const handleBack = () => {
    navigate("/industry/welcome");
  };
  const ComponentReq = () => {
    return (
      <Box
        minWidth="65vw"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -63%)",
            md: "translate(-50%, -50%)",
          },
          textAlign: "center",
        }}
      >
        <Box height="50px"></Box>
        <ProgressBar stepList={StepList} progress={20} stepIndex={0} />
        <Card
          sx={{
            marginTop: "20px",
            maxHeight: "60vh",
            maxWidth: "100%",
            padding: "50px",
            outline: "50px",
            borderRadius: "15px",
            background: "rgba(255, 255, 255, 0.04)",
            textAlign: "left",
            overflowY: "scroll",
          }}
        >
          <Box width="100%">
            <LargeTextSmallText mainText={"Title"} required={true} />
            <RNDTextField
              placeholder={"Provide a suitable title"}
              rows={1}
              maxRows={1}
            />
            <Box height="55px"></Box>
            <LargeTextSmallText mainText={"Background"} required={true} />
            <RNDTextField placeholder={"Context"} rows={4} maxRows={4} />
            <Box height="55px"></Box>
            <LargeTextSmallText
              mainText={"What are you looking for?"}
              required={true}
            />
            <RNDTextField
              placeholder={"Mention your expectations"}
              rows={3}
              maxRows={3}
            />
            <Box height="55px"></Box>
            <LargeTextSmallText
              mainText={"Preferred Solutions"}
              required={false}
            />
            <AddMore text={"Add solution"} />
            <Box height="72px"></Box>
            <BackContinueButtons
              backFun={handleBack}
              continueFun={handleContinue}
            />
          </Box>
        </Card>
      </Box>
    );
  };

  return <BackgroundScreen Component={ComponentReq} Title={"Background"} />;
};

export default RNDBg;
