import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import arrow from "../../../assets/arrow-up-right.png";

function About({ profile }) {
  console.log("Profile is for about:", profile);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: "15px",
        paddingLeft: "110px",
        gap: "20px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          float: "left",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
          }}
        >
          Experience
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            color: "var(--Gray-600, #475467)",
            textOverflow: "ellipsis",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {(profile?.research_experience?.length > 0 &&
            profile.research_experience[0].title) ||
            "No experience"}
        </Typography>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
              marginBottom: "10px",
            }}
          >
            About me
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            {profile?.profile_about || "No about added"}
          </Typography>
          <button
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "0",
            }}
          >
            <Typography
              sx={{
                color: "var(--Brand-700, #087443)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
              }}
            >
              Read more
            </Typography>
          </button>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-600, #475467)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Portfolio
              </Typography>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <Typography
                  sx={{
                    color: profile?.portfolio
                      ? "var(--Brand-700, #087443)"
                      : "var(--Gray-600, #475467)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {profile?.portfolio || "No portfolio"}
                </Typography>
                {profile?.portfolio ? <img src={arrow} alt="" /> : null}
              </button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-600, #475467)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Linkedin
              </Typography>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <Typography
                  sx={{
                    color: profile?.linkedin
                      ? "var(--Brand-700, #087443)"
                      : "var(--Gray-600, #475467)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {profile?.linkedin || "No linkedin"}
                </Typography>
                {profile?.linkedin ? <img src={arrow} alt="" /> : null}
              </button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "40px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-600, #475467)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Research Gate
              </Typography>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Brand-700, #087443)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  @amelielaurent
                </Typography>
                <img src={arrow} alt="" />
              </button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-600, #475467)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Email
              </Typography>
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  gap: "10px",
                  padding: "0",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Brand-700, #087443)",
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  {profile?.email}
                </Typography>
                <img src={arrow} alt="" />
              </button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default About;
