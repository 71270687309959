import React from "react";
import { Box, Card, Typography } from "@mui/material";
import briefcase_icon from "../../../assets/briefcase.png";
import file_icon from "../../../assets/file.png";
import stars_icon from "../../../assets/stars.png";
import user_icon from "../../../assets/user.png";
import "./SidePanel.css";

const list = [
  {
    icon: user_icon,
    title: "Basic Details",
    description: "Please provide all the required details",
  },
  {
    icon: briefcase_icon,
    title: "Research & Experience",
    description: "More details about your experience",
  },
  {
    icon: file_icon,
    title: "Research Projects",
    description: "Add your research works to your profile",
  },
  {
    icon: stars_icon,
    title: "Finalize Profile",
    description: "Add visuals to get noticed faster",
  },
];
const Tile = (props) => {
  const { index, icon, title, description, selectedIndex } = props;
  return (
    <Box
      sx={{
        marginLeft: "0px",
        marginRight: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        padding: "3px",
        gap: 2,
        // width: "80%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Card
          elevation="2"
          width="48px"
          height="48px"
          sx={{
            padding: "10px 12px",
            borderRadius: "10px",
            border:
              selectedIndex === index
                ? "2px solid var(--Gray-200, #EAECF0)"
                : "1px solid var(--Gray-200, #EAECF0)",
          }}
        >
          <img src={icon} alt="icon" />
        </Card>
        {index < list.length - 1 && <div className="vertical-line"></div>}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "flex-start",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "24px",
            textAlign: "center",
            opacity: selectedIndex === index ? 1 : 0.6,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "var(--Gray-500, #475467)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "20px",
            textAlign: "center",
            opacity: selectedIndex === index ? 1 : 0.6,
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const SidePanel = ({ selectedIndex }) => {
  return (
    <Box
      height={{ xs: "auto", md: "100vh" }}
      width="auto"
      padding="32px"
      bgcolor="var(--Gray-50, #F9FAFB)"
      display={{ xs: "none", md: "flex" }}
      flexDirection="column"
      gap={1}
      alignItems="center"
      //   justifyContent="center"
    >
      {list.map((item, index) => (
        <React.Fragment key={index}>
          <Tile
            index={index}
            icon={item.icon}
            title={item.title}
            description={item.description}
            selectedIndex={selectedIndex}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default SidePanel;
