import React from "react";
import BackgroundScreen from "../components/BackgroundScreen";
import { Box, Card } from "@mui/material";
import ProgressBar from "../components/ProgressBar";
import { StepList } from "../resources/StepList";
import LargeTextSmallText from "../components/LargeTextSmallText";
import AddMore from "../components/AddMore";
import BackContinueButtons from "../components/BackContinueButtons";
import { useNavigate } from "react-router-dom";
import RNDTextField from "../components/RNDTextField";
import RNDSelectMenu from "../components/RNDSelectMenu";
import LevelSlider from "../components/LevelSlider";
import { Steps } from "../resources/TechnologyAdaptSteps";

const RNDGoals = () => {
  const navigate = useNavigate();
  const dropDownList = [
    "ABC",
    "DEF",
    "GHI",
    "JKL",
    "MNO",
    "PQR",
    "STU",
    "VWX",
    "YZ",
  ];
  const [selectedField, setSelectedField] = React.useState(dropDownList[1]);
  const [specialisations, setSpecialisations] = React.useState(dropDownList[2]);
  const [methods, setMethods] = React.useState(dropDownList[3]);

  const handleContinue = () => {
    // navigate("/create-new-rnd/goals");
  };
  const handleBack = () => {
    navigate("/create-new-rnd/requirements");
  };
  const ComponentGoals = () => {
    return (
      <Box
        minWidth="65vw"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: {
            xs: "translate(-50%, -63%)",
            md: "translate(-50%, -50%)",
          },
          textAlign: "center",
        }}
      >
        <Box height="50px"></Box>
        <ProgressBar stepList={StepList} progress={63} stepIndex={2} />
        <Card
          sx={{
            marginTop: "20px",
            maxHeight: "63vh",
            maxWidth: "100%",
            padding: "50px",
            borderRadius: "15px",
            background: "rgba(255, 255, 255, 0.04)",
            textAlign: "left",
            overflowY: "scroll",
          }}
        >
          <LargeTextSmallText mainText={"Field of study"} required={true} />
          <RNDSelectMenu
            id={"field"}
            selectedItem={selectedField}
            setSelectedItem={setSelectedField}
            dropdownList={dropDownList}
          />
          <Box height="45px"></Box>
          <LargeTextSmallText mainText={"Specializations"} required={true} />
          <RNDSelectMenu
            id={"specialisation"}
            selectedItem={specialisations}
            setSelectedItem={setSpecialisations}
            dropdownList={dropDownList}
          />
          <Box height="45px"></Box>
          <LargeTextSmallText mainText={"Methods"} required={true} />
          <RNDSelectMenu
            id={"methods"}
            selectedItem={methods}
            setSelectedItem={setMethods}
            dropdownList={dropDownList}
          />
          <Box height="45px"></Box>
          <LargeTextSmallText
            mainText={"Technology adaptation level"}
            required={true}
          />
          <Box height="10px"></Box>

          <LevelSlider steps={Steps} />
          <Box height="72px"></Box>

          <BackContinueButtons
            backFun={handleBack}
            continueFun={handleContinue}
          />
        </Card>
      </Box>
    );
  };

  return <BackgroundScreen Component={ComponentGoals} Title={"Goals"} />;
};

export default RNDGoals;
