import React from "react";
import { Box, Card, Typography } from "@mui/material";
import BackgroundComp from "./components/background_comp";
import SidePanel from "./components/side_panel";
import user_icon from "../../assets/user.png";
import InputField from "./components/input_field";
import "./SearchBox.css";
import trash_icon from "../../assets/trash.png";
import updateResearcherProfile from "../../helper/updateResearcherProfile";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const inputFieldList = [
  {
    label: "Phone Number",
    placeholder: "Enter your phone number",
    slug: "phone",
    required: true,
  },
  {
    label: "LinkedIn Profile Link",
    placeholder: "Enter your linkedin profile link",
    slug: "linkedin",
    required: true,
  },
  {
    label: "Website/Portfolio Url",
    placeholder: "Paste the Url",
    slug: "portfolio",
    required: true,
  },
];

const ProfileDetails = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    phone: "",
    linkedin: "",
    portfolio: "",
    social_links: [],
    stage: 0,
  });
  const [socialLink, setSocialLink] = React.useState("");
  const [links, setLinks] = React.useState([]);
  const LinkView = ({ link, index }) => {
    return (
      <Box
        display="flex"
        justifyContent={"space-between"}
        sx={{
          borderRadius: "8px",
          border: "1px solid var(--Gray-300, #D0D5DD)",
          background: "var(--Base-White, #FFF)",
          boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          display: "flex",
          padding: "12px 14px",
          alignItems: "center",
          width: "90%",
        }}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          {link}
        </Typography>
        <img
          src={trash_icon}
          alt="trash"
          onClick={() => {
            const newLinks = links.filter((l, i) => i !== index);
            setLinks(newLinks);
            setData({ ...data, social_links: newLinks });
          }}
        />
      </Box>
    );
  };
  const handleAddLink = ({ link }) => {
    if (link === "") return;
    const newLinks = [...links, link];
    setLinks(newLinks);
    setSocialLink("");
    setData({ ...data, social_links: newLinks });
  };

  const handleContinue = async () => {
    console.log("Data", data);
    if (
      data.phone === "" ||
      data.linkedin === "" ||
      data.social_links.length === 0 ||
      data.portfolio === ""
    ) {
      alert("Please fill all the required fields");
      return;
    }

    try {
      setLoading(true);
      const response = await updateResearcherProfile(data);
      console.log(response);
      navigate("/researcher/research-and-exp");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        // bgcolor: "red",
      }}
    >
      <Card
        elevation="0"
        width="52px"
        height="52px"
        sx={{
          padding: "12px 14px",
          borderRadius: "10px",
          border: "1px solid var(--Gray-200, #EAECF0)",
        }}
      >
        <img src={user_icon} alt="icon" />
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "360px",
          boxShadow: "none",
          bgcolor: "transparent",
          gap: "24px",
          border: "none",
          // overflow: "scroll",
          // height: "20rem",
        }}
      >
        {/* <img src={logo} alt="" /> */}
        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "38px",
          }}
        >
          Profile Details
        </Typography>

        {inputFieldList.map((input, index) => (
          <InputField
            key={index}
            label={input.label}
            placeholder={input.placeholder}
            required={input.required}
            value={data[input.slug]}
            onChange={(e) => {
              setData({ ...data, [input.slug]: e.target.value });
            }}
          />
        ))}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "6px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Social Links*
          </Typography>
          <div class="searchbox-wrap">
            <input
              type="text"
              placeholder="Search for something..."
              value={socialLink}
              onChange={(e) => {
                setSocialLink(e.target.value);
              }}
            />
            <button
              onClick={() =>
                socialLink != "" ? handleAddLink({ link: socialLink }) : null
              }
            >
              Add
            </button>
          </div>
          {links.map((link, index) => (
            <LinkView key={index} link={link} index={index} />
          ))}
        </Box>

        <button
          onClick={handleContinue}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Brand-600, #099250)",
            background: "var(--Brand-600, #099250)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
          }}
        >
          {loading ? (
            <CircularProgress sx={{ color: "#FEFEFE" }} />
          ) : (
            <Typography
              sx={{
                color: "var(--Base-White, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Continue
            </Typography>
          )}
        </button>
      </Card>
    </Box>
  );
};

const ResearcherProfileDetails = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
      }}
    >
      <SidePanel selectedIndex={0} />
      <BackgroundComp Component={ProfileDetails} />
    </Box>
  );
};

export default ResearcherProfileDetails;
