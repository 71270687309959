export const Steps = [
    {
      label: "Basic principles observed",
      description: "Initial observations of fundamental principles or concepts relevant to the project.",
    },
    {
      label: "Concept development",
      description: "Further development and refinement of the project's underlying concept.",
    },
    {
      label: "Experimental proof of concept",
      description: "Conducting experiments to validate the feasibility of the project's concept.",
    },
    {
      label: "Validated in laboratory conditions",
      description: "Testing and validation of the project's concept within controlled laboratory settings.",
    },
    {
      label: "Validated in relevant environment",
      description: "Testing and validation of the project's concept in an environment closely related to its intended application.",
    },
    {
      label: "Demonstrated in relevant environment",
      description: "Successful demonstration of the project's concept in a relevant environment, showcasing its practicality.",
    },
    {
      label: "Regulatory approval",
      description: "Seeking and obtaining necessary approvals and certifications from regulatory bodies.",
    },
    {
      label: "Product in production",
      description: "Commencement of the manufacturing or production process for the project.",
    },
    {
      label: "Product in the market",
      description: "The project's product is now available and accessible to the target market.",
    },
  ];
  