import React from "react";
import { Box, Card, Typography, TextField, IconButton } from "@mui/material";
import BackgroundComp from "./components/background_comp";
import SidePanel from "./components/side_panel";
import briefcase from "../../assets/briefcase.png";
import InputField from "./components/input_field";
import "./SearchBox.css";
import updateResearcherProfile from "../../helper/updateResearcherProfile";
import { useNavigate } from "react-router-dom";
import trash_icon from "../../assets/new_trash.png";
import red_trash_icon from "../../assets/trash.png";
import { CircularProgress } from "@mui/material";

import edit_icon from "../../assets/edit.png";

const expInputFieldList = [
  {
    label: "Title",
    placeholder: "Add experience title",
    required: true,
    slug: "title",
  },
  {
    label: "Organization/Institution Name",
    placeholder: "Add organization/institution name",
    required: true,
    slug: "organization",
  },
];

const ExpTile = ({
  title,
  organization,
  start_date,
  end_date,
  index,
  setShowExpForm,
  data,
  setData,
  expData,
  setExpData,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"space-between"}
      alignItems={"flex-start"}
      sx={{
        borderRadius: "8px",
        border: "1px solid var(--Gray-300, #D0D5DD)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        display: "flex",
        padding: "12px 14px",
        alignItems: "center",
        width: "90%",
        gap: "12px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap="6px"
        width={"100%"}
      >
        <Box>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "20px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            {organization}
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={() => {
              //Delete the experience
              const newExp = data.research_experience.filter(
                (l, i) => i !== index
              );
              setData({ ...data, research_experience: newExp });
              setExpData({
                title,
                organization,
                start_date,
                end_date,
              });
              setShowExpForm(true);
            }}
          >
            <img src={edit_icon} alt="trash" />
          </IconButton>
          <IconButton
            onClick={() => {
              //Delete the experience
              const newExp = data.research_experience.filter(
                (l, i) => i !== index
              );
              setData({ ...data, research_experience: newExp });
            }}
          >
            <img src={trash_icon} alt="trash" />
          </IconButton>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
        alignItems="center"
        gap="6px"
        width={"100%"}
      >
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          {start_date}
        </Typography>
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          - {end_date}
        </Typography>
      </Box>
    </Box>
  );
};

const ExpForm = ({ data, setData, setShowExpForm, expData, setExpData }) => {
  const label = { "aria-label": "Checkbox demo" };

  const [presentlyWorking, setPresentlyWorking] = React.useState(false);

  const handleExpChange = (e) => {
    setExpData({ ...expData, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (presentlyWorking) {
      setExpData({ ...expData, end_date: "Present" });
    }
  }, [presentlyWorking]);

  const handleExpSave = (e) => {
    console.log("ExpData", expData);
    if (
      expData.title === "" ||
      expData.organization === "" ||
      expData.start_date === "" ||
      expData.end_date === ""
    ) {
      alert("Please fill all the fields");
      return;
    }

    //TODO: Add validation for start_date and end_date
    if (expData.start_date > expData.end_date) {
      alert("Start date cannot be greater than end date");
      return;
      //TODO: Show error message
    }
    //TODO: checkbox for presently working here
    if (presentlyWorking) {
      expData.end_date = "Present";
    }
    setData({
      ...data,
      research_experience: [...data.research_experience, expData],
    });
    setExpData({ title: "", organization: "", start_date: "", end_date: "" });
    console.log("Data", data);
    setShowExpForm(false);
  };

  const handleExpCancel = (e) => {
    setExpData({ title: "", organization: "", start_date: "", end_date: "" });
    setShowExpForm(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "24px",
        width: "100%",
      }}
    >
      {expInputFieldList.map((input, index) => (
        <InputField
          key={index}
          label={input.label}
          placeholder={input.placeholder}
          required={input.required}
          value={expData[input.slug]}
          onChange={(e) => {
            setExpData({ ...expData, [input.slug]: e.target.value });
          }}
          name={input.slug}
        />
      ))}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          gap: 1, // Add some gap between the DatePickers
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            //   width: "100%",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            Start Date *
          </Typography>
          <TextField
            value={expData.start_date}
            onChange={handleExpChange}
            name="start_date"
            // label="Start Date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "44px", // Adjust input height
                padding: "10px 14px", // Adjust padding as needed, format: 'top/bottom left/right'
              },
              "& .MuiOutlinedInput-root": {
                height: "44px", // You might need to adjust this depending on your needs
                fontSize: "0.875rem", // Adjust font size as needed
                borderRadius: "8px",
              },
              fontFamily: "Poppins",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
            //   width: "100%",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "20px",
            }}
          >
            End Date *
          </Typography>

          <TextField
            value={expData.end_date}
            onChange={handleExpChange}
            name="end_date"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              "& .MuiInputBase-input": {
                height: "44px", // Adjust input height
                padding: "10px 14px", // Adjust padding as needed, format: 'top/bottom left/right'
              },
              "& .MuiOutlinedInput-root": {
                height: "44px", // You might need to adjust this depending on your needs
                fontSize: "0.875rem", // Adjust font size as needed
                borderRadius: "8px",
              },
              fontFamily: "Poppins",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "8px",
          width: "100%",
        }}
      >
        <input
          type="checkbox"
          {...label}
          onChange={(e) => {
            setPresentlyWorking(e.target.checked);
          }}
        />
        <Typography
          sx={{
            color: "var(--Gray-700, #344054)",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "20px",
          }}
        >
          Presently working here
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "8px",
          width: "100%",
        }}
      >
        <button
          onClick={handleExpSave}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            //   width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Brand-600, #099250)",
            background: "var(--Brand-600, #099250)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Base-White, #FFF)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Save Experience
          </Typography>
        </button>
        <button
          onClick={handleExpCancel}
          style={{
            display: "flex",
            padding: "7px 16px",
            justifyContent: "center",
            alignItems: "center",
            gap: "6px",
            //   width: "100%",
            borderRadius: "8px",
            border: "1px solid var(--Gray-300, #D0D5DD)",
            background: "var(--Base-White, #FFF)",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            flexGrow: 1,
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "24px",
            }}
          >
            Cancel
          </Typography>
        </button>
      </Box>
    </Box>
  );
};

const LinkView = ({ link, index, setData, data, links, listName }) => {
  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      sx={{
        borderRadius: "8px",
        border: "1px solid var(--Gray-300, #D0D5DD)",
        background: "var(--Base-White, #FFF)",
        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
        display: "flex",
        padding: "12px 14px",
        alignItems: "center",
        width: "90%",
      }}
    >
      <Typography
        sx={{
          color: "var(--Gray-700, #344054)",
          fontFamily: "Poppins",
          fontSize: "14px",
          fontStyle: "normal",
          fontWeight: 500,
          lineHeight: "20px",
        }}
      >
        {link}
      </Typography>
      <img
        src={red_trash_icon}
        alt="trash"
        onClick={() => {
          const newLinks = links.filter((l, i) => i !== index);
          setData({ ...data, [listName]: newLinks });
        }}
      />
    </Box>
  );
};

const FinalExpComp = () => {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const [data, setData] = React.useState({
    research_experience: [],
    research_specialization: [],
    research_method: [],
    research_area: [],
    stage: 1,
  });

  const [researchData, setResearchData] = React.useState({
    research_area: "",
    research_specialization: "",
    research_method: "",
  });

  const [expData, setExpData] = React.useState({
    title: "",
    organization: "",
    start_date: "",
    end_date: "",
  });

  const [showExpForm, setShowExpForm] = React.useState(false);

  const handleContinue = async () => {
    if (data.research_experience.length === 0) {
      alert("Please add at least one experience");
      return;
    }
    if (data.research_area.length === 0) {
      alert("Please add at least one research area");
      return;
    }
    if (data.research_specialization.length === 0) {
      alert("Please add at least one research specialization");
      return;
    }
    if (data.research_method.length === 0) {
      alert("Please add at least one research method");
      return;
    }

    console.log("Data", data);
    try {
      setLoading(true);
      const response = await updateResearcherProfile(data);
      console.log(response);
      navigate("/researcher/research-projects");
      // navigate("/");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 3,
        // bgcolor: "red",
      }}
    >
      <Card
        elevation="0"
        width="52px"
        height="52px"
        sx={{
          padding: "12px 14px",
          borderRadius: "10px",
          border: "1px solid var(--Gray-200, #EAECF0)",
        }}
      >
        <img src={briefcase} alt="icon" />
      </Card>
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "360px",
          boxShadow: "none",
          bgcolor: "transparent",
          gap: "24px",
          border: "none",
          // overflow: "scroll",
          // height: "20rem",
        }}
      >
        {/* <img src={logo} alt="" /> */}
        <Typography
          sx={{
            color: "var(--Gray-900, #101828)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "38px",
          }}
        >
          Research & Experience
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "24px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              lineHeight: "26px",
              textAlign: "left",
              fontWeight: 600,
            }}
          >
            Experience
          </Typography>
          {data.research_experience.length === 0 ? (
            <ExpForm
              data={data}
              setData={setData}
              setShowExpForm={setShowExpForm}
              expData={expData}
              setExpData={setExpData}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "24px",
                width: "100%",
              }}
            >
              {data.research_experience.map((exp, index) => (
                <ExpTile // Assuming ExpTile is your component, renamed to start with a capital letter
                  key={index}
                  index={index}
                  title={exp.title}
                  organization={exp.organization}
                  start_date={exp.start_date}
                  end_date={exp.end_date}
                  setShowExpForm={setShowExpForm}
                  data={data}
                  setData={setData}
                  expData={expData}
                  setExpData={setExpData}
                />
              ))}
              <button
                onClick={() => setShowExpForm(true)}
                style={{
                  display: "flex",
                  padding: "7px 16px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "6px",
                  width: "100%",
                  borderRadius: "8px",
                  border: "1px solid var(--Brand-600, #099250)",
                  background: "var(--Brand-600, #099250)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--Base-White, #FFF)",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "24px",
                  }}
                >
                  Add more
                </Typography>
              </button>
            </Box>
          )}
          {showExpForm && data.research_experience.length >= 1 ? (
            <ExpForm
              data={data}
              setData={setData}
              setShowExpForm={setShowExpForm}
              expData={expData}
              setExpData={setExpData}
            />
          ) : null}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "24px",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "var(--Gray-700, #344054)",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              lineHeight: "26px",
              textAlign: "left",
              fontWeight: 600,
            }}
          >
            Research Area
          </Typography>
          <div class="searchbox-wrap">
            <input
              name="research_area"
              type="text"
              placeholder="Add research area"
              value={researchData.research_area}
              onChange={(e) => {
                setResearchData({
                  ...researchData,
                  research_area: e.target.value,
                });
              }}
            />
            <button
              onClick={() => {
                if (researchData.research_area === "") {
                  alert("Please enter a value");
                  return;
                }
                setData({
                  ...data,
                  research_area: [
                    ...data.research_area,
                    researchData.research_area,
                  ],
                });
                setResearchData({ ...researchData, research_area: "" });
              }}
            >
              Add
            </button>
          </div>
          {data.research_area.map((link, index) => (
            <LinkView
              key={index}
              link={link}
              index={index}
              links={data.research_area}
              listName={"research_area"}
              setData={setData}
              data={data}
            />
          ))}
          <div class="searchbox-wrap">
            <input
              name="research_specialization"
              type="text"
              placeholder="Add research specialization"
              value={researchData.research_specialization}
              onChange={(e) => {
                setResearchData({
                  ...researchData,
                  research_specialization: e.target.value,
                });
              }}
            />
            <button
              onClick={() => {
                if (researchData.research_specialization === "") {
                  alert("Please enter a value");
                  return;
                }
                setData({
                  ...data,
                  research_specialization: [
                    ...data.research_specialization,
                    researchData.research_specialization,
                  ],
                });
                setResearchData({
                  ...researchData,
                  research_specialization: "",
                });
              }}
            >
              Add
            </button>
          </div>
          {data.research_specialization.map((link, index) => (
            <LinkView
              key={index}
              link={link}
              index={index}
              links={data.research_specialization}
              listName={"research_specialization"}
              setData={setData}
              data={data}
            />
          ))}
          <div class="searchbox-wrap">
            <input
              name="research_method"
              type="text"
              placeholder="Add research methods"
              value={researchData.research_method}
              onChange={(e) => {
                setResearchData({
                  ...researchData,
                  research_method: e.target.value,
                });
              }}
            />
            <button
              onClick={() => {
                if (researchData.research_method === "") {
                  alert("Please enter a value");
                  return;
                }
                setData({
                  ...data,
                  research_method: [
                    ...data.research_method,
                    researchData.research_method,
                  ],
                });
                setResearchData({ ...researchData, research_method: "" });
              }}
            >
              Add
            </button>
          </div>
          {data.research_method.map((link, index) => (
            <LinkView
              key={index}
              link={link}
              index={index}
              links={data.research_method}
              listName={"research_method"}
              setData={setData}
              data={data}
            />
          ))}
          <button
            onClick={handleContinue}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Continue
              </Typography>
            )}
          </button>
        </Box>
      </Card>
    </Box>
  );
};

const ResearchAndExp = () => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        height: "100vh",
      }}
    >
      <SidePanel selectedIndex={1} />
      <BackgroundComp Component={FinalExpComp} />
    </Box>
  );
};

export default ResearchAndExp;
