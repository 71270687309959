import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import Sidebar from "../../components/Sidebar";
import AltNav from "../../components/AltNav";
import SortOutlinedIcon from "@mui/icons-material/SortOutlined";
import TuneIcon from "@mui/icons-material/Tune";
import img1 from "../../assets/company1.svg";
import img2 from "../../assets/img2.png";
import img3 from "../../assets/img3.png";
import img4 from "../../assets/img4.png";
import ProjectCards from "./components/ProjectCards";

const ProjectCardData = [
  {
    img: img1,
    title: "Mechanically recycled polymers that perform like virgin polymers.",
    status: "Active",
    color: "#3FA268",
    aors: ["Materials", "Biology", "Medicine"],
    updatedOn: "Uploaded on: 12th Aug, 2023",
  },
  {
    img: img2,
    title:
      "Recyclable materials, additives, or coatings for monolayer barriers",
    status: "Resume Review",
    color: "#F2994A",
    aors: ["Chemistry", "Materials"],
    updatedOn: "Uploaded on: 01 Aug, 2023",
  },
  {
    img: img3,
    title:
      "Screening system to identify SGLT1, GLUT5 inhibitors and activators",
    status: "Waiting for Approval",
    color: "#F2994A",
    aors: ["Biology", "Medicine"],
    updatedOn: "Uploaded on: 31st July, 2023",
  },
  {
    img: img4,
    title:
      "Bio-based resins and extrusion technologies for rigid plastic packaging applications",
    status: "Active",
    color: "#3FA268",
    aors: ["Materials", "Biology", "Medicine"],
    updatedOn: "Uploaded on: 10th Aug, 2023",
  },
];

const ProjectsMain = () => {
  return (
    <Box
      sx={{
        overflowY: "hidden",
        overflowX: "hidden",
        background:
          "radial-gradient(39.33% 60.33% at 50% 50%, #1F3B2A 20%, #272A31 100%)",
        backdropFilter: "blur(2.799999952316284px)",
        width: "100vw",
        display: "flex",
      }}
      height="100vh"
    >
      <Sidebar currentPage={"Projects"} />
      <Box
        width="75vw"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        padding={{ xs: "0px", md: "0px 20px" }}
      >
        <AltNav btn_text={"Create A Project"} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography
              sx={{
                color: "var(--Primary-White, #FEFEFE)",
                fontFamily: "Poppins",
                fontSize: "28px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "38px",
              }}
            >
              Projects
            </Typography>
            <Typography
              sx={{
                color: "var(--Gray-6, #F2F2F2)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Outsource, track and manage your projects here.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "#101215",
                borderRadius: "8px",
                padding: "10px 16px",
                marginRight: "10px",
                "&:hover": {
                  background: "#101215",
                  color: "#fff",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  marginLeft: "10px",
                }}
              >
                Sort
              </Typography>
              <SortOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                background: "#101215",
                borderRadius: "8px",
                padding: "10px 16px",
                // marginRight: "10px",
                "&:hover": {
                  background: "#101215",
                  color: "#fff",
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "24px",
                  marginLeft: "10px",
                }}
              >
                Filter
              </Typography>
              <TuneIcon />
            </IconButton>
          </Box>
        </Box>
        <Box height="34px"></Box>
        <Grid container spacing={2}>
          {ProjectCardData.map((project) => (
            <ProjectCards
              img={project.img}
              title={project.title}
              status={project.status}
              color={project.color}
              aors={project.aors}
              updatedOn={project.updatedOn}
            />
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectsMain;
