import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/Background_pattern_decorative.png";
import bg_mb from "../../assets/Background_pattern_decorative_mb.png";
import logo from "../../assets/logo.png";
import "./ForgotPassword.css";
import { CircularProgress } from "@mui/material";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import forgotPasswordHelper from "../../helper/forgotPasswordHelper";
import axios from "axios";

// const label = { "aria-label": "Checkbox demo" };

const ForgotPasswordMain = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [radioValue, setRadioValue] = React.useState("researcher");
  const [data, setData] = React.useState({
    email: "",
    userType: "",
  });

  async function handleContinue() {
    if (data.email === "") {
      alert("Please enter your email");
      return;
    }
    if (radioValue === "researcher") {
      setData({ ...data, userType: "researcher" });
    } else {
      setData({ ...data, userType: "industry" });
    }
    console.log("Data", data);
    try {
      setLoading(true);
      const resp = await forgotPasswordHelper({
        email: data.email,
        userType: radioValue,
      });
      console.log("Response", resp);
      alert("Link sent successfully");
    } catch (error) {
      console.log("Error in forgotPassword frntend", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={bg} alt="Background" className="bg-desktop" />
      <img src={bg_mb} alt="Background Mobile" className="bg-mobile" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "7%",
          padding: { xs: "0 16px" },
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "360px",
            boxShadow: "none",
            bgcolor: "transparent",
            gap: "24px",
            border: "none",
          }}
        >
          <img src={logo} alt="" />
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "38px",
            }}
          >
            Forgot Password
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Enter your email and we will send you a link to reset your password
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Who are you?
            </Typography>
            <RadioGroup
              value={radioValue}
              onChange={(e) => setRadioValue(e.target.value)}
              row
              aria-label="position"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                value="researcher"
                control={
                  <Radio
                    color="primary"
                    sx={{
                      color: "green", // Change to your desired color
                      "&.Mui-checked": {
                        color: "green", // Change to your desired color
                      },
                    }}
                  />
                }
                label="Researcher"
                labelPlacement="end"
              />
              <FormControlLabel
                value="industry"
                control={
                  <Radio
                    color="primary"
                    sx={{
                      color: "green", // Change to your desired color
                      "&.Mui-checked": {
                        color: "green", // Change to your desired color
                      },
                    }}
                  />
                }
                label="Industry"
                labelPlacement="end"
              />
            </RadioGroup>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Email
            </Typography>
            <input
              value={data.email}
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                display: "flex",
                padding: "10px 14px",
                alignItems: "center",
                gap: "8px",
                width: "90%",
              }}
              placeholder="Enter your email"
            />
          </Box>

          <button
            onClick={handleContinue}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Send Link
              </Typography>
            )}
          </button>
        </Card>
      </Box>
    </Box>
  );
};

export default ForgotPasswordMain;
