import { Box, Typography } from "@mui/material";
import React from "react";
import error_img from "../../assets/error.png";

const ErrorMain = () => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={error_img} alt="error" style={{ width: "30%" }} />
      {/* <Typography variant="h5" sx={{ marginTop: "20px" }}>
        404 - Page Not Found
      </Typography> */}
    </Box>
  );
};

export default ErrorMain;
