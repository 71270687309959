import {
  Box,
  Card,
  Radio,
  Typography,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import baseURL from "../../resources/BaseUrl";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/Background_pattern_decorative.png";
import bg_mb from "../../assets/Background_pattern_decorative_mb.png";
import google from "../../assets/google.png";
import logo from "../../assets/logo.png";
import "./Signup.css";
import Modal from "@mui/material/Modal";
import checkIcon from "../../assets/CheckIcon.svg";
import scope from "../../assets/scope.png";
import industry from "../../assets/industry.png";
import ModalBox from "./components/ModalBox";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import student_icon from "../../assets/student.png";
import scientist_icon from "../../assets/scientist.png";
import prof_icon from "../../assets/prof.png";
import ResearcherModalBox from "./components/ResearcherModalBox";
import createResearcherProfile from "../../helper/createResearcherProfile";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import createIndustryProfile from "../../helper/createIndustryProfile";

const label = { "aria-label": "Checkbox demo" };

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%", // Adjusted width for mobile screens
  maxWidth: "600px", // Maximum width for larger screens
  maxHeight: "80%", // Adjusted height for mobile screens
  overflowY: "auto", // Enable scrolling if content overflows
  boxShadow: 24,
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "17px",
  borderRadius: "12px",
  background: "var(--Base-White, #FFF)",
  boxShadow:
    "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
};

const SignupMain = () => {
  const [data, setDta] = React.useState({
    name: "",
    email: "",
    password: "",
  });
  const [loading, setLoading] = React.useState(false);
  const [researcherModalOpen, setResearcherModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    // setDta({ name: "", email: "", password: "" });
    setOpen(false);
  };
  const [user, setUser] = React.useState(null);
  const [profile, setProfile] = React.useState([]);
  const handleResarcherModalOpen = () => setResearcherModalOpen(true);
  const handleResarcherModalClose = () => {
    // setDta({ name: "", email: "", password: "" });

    setResearcherModalOpen(false);
  };
  const navigate = useNavigate();
  const [eightCharacters, setEightCharacters] = React.useState(false);
  const [specialCharacter, setSpecialCharacter] = React.useState(false);
  const [modalSelectedIndex, setModalSelectedIndex] = React.useState(0);
  const [researcherSelected, setResearcherSelected] = React.useState(0);

  const handleResearcherModalSelection = (index) => {
    console.log("Index", index);
    setResearcherSelected(index);
  };

  // const [radioValue, setRadioValue] = React.useState("researcher");

  const handleGetStarted = () => {
    // console.log("Data", radioValue);
    if (data.name && data.email && data.password) {
      if (!eightCharacters || !specialCharacter) {
        alert(
          "Password must be 8 characters long and contain a special character"
        );
        return;
      }
      if (!data.email.includes("@")) {
        alert("Please enter a valid email");
        return;
      }
      handleOpen();
    }
  };

  const handleModalContinue = async () => {
    console.log("Data", data);
    if (modalSelectedIndex === 0) {
      handleClose();
      handleResarcherModalOpen();
    } else {
      console.log("Industry");
      try {
        const res = await createIndustryProfile(data);
        console.log("Completed creating industry profile");
        navigate("/industry/profile-details");
      } catch (err) {
        setDta({ name: "", email: "", password: "" });
        console.log("Error data", err.message);
        if (err.message === "Request failed with status code 403") {
          alert("Please use work email for industry account");
          return;
        }
        if (err.message === "Request failed with status code 401") {
          alert("Email already exists");
          return;
        }
      }
    }
  };

  const handleResearcherModalContinue = async () => {
    var researcherType = "";
    if (researcherSelected === 0) {
      researcherType = "student";
    } else if (researcherSelected === 1) {
      researcherType = "professor";
    } else {
      researcherType = "scientist";
    }
    console.log("Researcher Type", researcherType);
    console.log("Data : ", data);
    data.type = researcherType;
    var res;
    try {
      setLoading(true);
      res = await createResearcherProfile(data);
      console.log("Completed creating researcher profile");
      navigate("/researcher/profile-details");
    } catch (err) {
      setDta({ name: "", email: "", password: "" });

      console.log("Error data", err.message);
      if (err.message === "Request failed with status code 403") {
        alert("Please use work email");
        return;
      }
      if (err.message === "Request failed with status code 401") {
        alert("Email already exists");
        return;
      }
      // if(err.responsec)
    } finally {
      setLoading(false);
    }
  };

  const handleModalSelect = (index) => {
    console.log("Index", index);
    setModalSelectedIndex(index);
  };

  // const login = useGoogleLogin({
  //   onSuccess: (codeResponse) => setUser(codeResponse),
  //   onError: (error) => console.log("Login Failed:", error),
  // });
  const googleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      console.log("Code Response", codeResponse);
      setUser(codeResponse);
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse?.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse?.access_token}`,
              Accept: "application/json",
            },
          }
        );
        setDta({
          ...data,
          email: res.data.email,
          name: res.data.name,
          password: res.data.id,
        });
        handleOpen();
      } catch (err) {
        setDta({ name: "", email: "", password: "" });

        console.log("Error", err.message);
      }
    },
    onError: () => {
      // Handle login errors here
      alert("Google login failed");
      console.error("Google login failed");
    },
    // flow: "auth-code",
  });

  React.useEffect(() => {
    if (data.password.length >= 8) {
      setEightCharacters(true);
    } else {
      setEightCharacters(false);
    }
    if (data.password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
      setSpecialCharacter(true);
    } else {
      setSpecialCharacter(false);
    }
  }, [data.password]);

  return (
    <Box
      sx={{
        display: "block",
        // flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "scroll",
        overflowX: "hidden",
        height: "100vh",
        // bgcolor: "red",
        backgroundImage: `url(${bg})`,
        backgroundSize: { xs: "150%", md: "50%" },

        backgroundRepeat: "no-repeat",
        backgroundPosition: "top",
      }}
    >
      {/* <img src={bg} alt="Background" className="bg-desktop" />
      <img src={bg_mb} alt="Background Mobile" className="bg-mobile" /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // position: "absolute",
          // top: "10%",
          padding: { xs: "20px 0px", md: "20px 24px" },
          width: "100%",
          // height: "100vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minWidth: { xs: "90%", md: "360px" },
            boxShadow: "none",
            bgcolor: "transparent",
            gap: "24px",
            border: "none",
            marginLeft: "auto",
            marginRight: "auto",
            // padding: "0px 50px",
          }}
        >
          <img src={logo} alt="" />

          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "38px",
            }}
          >
            Create an account
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Start your 30-day free trial.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "6px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Name
              </Typography>
              <input
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--Gray-300, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  display: "flex",
                  padding: "10px 14px",
                  alignItems: "center",
                  gap: "8px",
                  width: "90%",
                }}
                placeholder="Enter your name"
                value={data.name}
                onChange={(e) => setDta({ ...data, name: e.target.value })}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "6px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Email
              </Typography>
              <input
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--Gray-300, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  display: "flex",
                  padding: "10px 14px",
                  alignItems: "center",
                  gap: "8px",
                  width: "90%",
                }}
                placeholder="Enter your email"
                value={data.email}
                onChange={(e) => setDta({ ...data, email: e.target.value })}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "6px",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Password
              </Typography>
              <input
                type="password"
                style={{
                  borderRadius: "8px",
                  border: "1px solid var(--Gray-300, #D0D5DD)",
                  background: "var(--Base-White, #FFF)",
                  boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  display: "flex",
                  padding: "10px 14px",
                  alignItems: "center",
                  gap: "8px",
                  width: "90%",
                }}
                placeholder="Create a password"
                value={data.password}
                onChange={(e) => setDta({ ...data, password: e.target.value })}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <CheckCircleIcon
                sx={{
                  color: eightCharacters
                    ? "var(--Brand-600, #099250)"
                    : "var(--Gray-300, #D0D5DD)",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Must be at least 8 characters
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <CheckCircleIcon
                sx={{
                  color: specialCharacter
                    ? "var(--Brand-600, #099250)"
                    : "var(--Gray-300, #D0D5DD)",
                  fontSize: "20px",
                }}
              />
              <Typography
                sx={{
                  color: "var(--Gray-700, #344054)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "20px",
                }}
              >
                Must contain one special character
              </Typography>
            </Box>
          </Box>
          <button
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
              cursor: "pointer",
            }}
            onClick={handleGetStarted}
          >
            <Typography
              sx={{
                color: "var(--Base-White, #FFF)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Get Started
            </Typography>
          </button>
          {/* <a
            href={`http://localhost:3004/auth/researcher/google`}
            style={{ textDecoration: "none", width: "100%" }}
          > */}

          <button
            onClick={() => googleLogin()}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Gray-300, #D0D5DD)",
              background: "var(--Base-White, #FFF)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            <img
              src={google}
              style={{
                height: "20px",
                width: "20px",
              }}
            />
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Sign in with Google
            </Typography>
          </button>
          {/* </a> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
              gap: "4px",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Already have an account?
            </Typography>
            <Typography
              onClick={() => navigate("/login")}
              sx={{
                color: "var(--Brand-700, #087443)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "20px",
                cursor: "pointer",
              }}
            >
              Log in
            </Typography>
          </Box>
        </Card>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                border: "1px solid var(--Success-25, #F6FEF9)",
                width: "38px",
                height: "38px",
                background: "var(--Success-50, #ECFDF3)",
              }}
            >
              <img
                src={logo}
                style={{
                  height: "26px",
                  width: "26px",
                }}
              />
            </Box>
            <Typography
              sx={{
                color: "var(--Gray-900, #101828)",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              Who are you?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <ModalBox
              icon={scope}
              title={"Researcher"}
              content={
                "Looking for applying to projects and building a profile?"
              }
              index={0}
              handleSelection={handleModalSelect}
              className={"researcher"}
              selected={modalSelectedIndex}
            />
            <ModalBox
              icon={industry}
              title={"Industry"}
              content={"Looking for researcher for your R&D projects?"}
              index={1}
              handleSelection={handleModalSelect}
              className={"industry"}
              selected={modalSelectedIndex}
            />
          </Box>
          <button
            onClick={handleModalContinue}
            style={{
              display: "flex",
              padding: "10px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              flex: "1 0 0",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            <Typography
              sx={{
                color: "var(--Base-White, #FFF)",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "24px",
              }}
            >
              Continue
            </Typography>
          </button>
        </Box>
      </Modal>
      <Modal
        open={researcherModalOpen}
        onClose={handleResarcherModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "50%",
                border: "1px solid var(--Success-25, #F6FEF9)",
                width: "38px",
                height: "38px",
                background: "var(--Success-50, #ECFDF3)",
              }}
            >
              <img
                src={logo}
                style={{
                  height: "26px",
                  width: "26px",
                }}
              />
            </Box>
            <Typography
              sx={{
                color: "var(--Gray-900, #101828)",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "28px",
              }}
            >
              What suits you the best?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <ResearcherModalBox
              icon={student_icon}
              title={"Student"}
              index={0}
              handleSelection={handleResearcherModalSelection}
              className={"student"}
              selected={researcherSelected}
            />
            <ResearcherModalBox
              icon={prof_icon}
              title={"Professor"}
              index={1}
              handleSelection={handleResearcherModalSelection}
              className={"prof"}
              selected={researcherSelected}
            />
            <ResearcherModalBox
              icon={scientist_icon}
              title={"Scientist"}
              index={2}
              handleSelection={handleResearcherModalSelection}
              className={"scientist"}
              selected={researcherSelected}
            />
          </Box>
          <button
            onClick={handleResearcherModalContinue}
            style={{
              display: "flex",
              padding: "10px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              flex: "1 0 0",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Continue
              </Typography>
            )}
          </button>
        </Box>
      </Modal>
    </Box>
  );
};

export default SignupMain;
