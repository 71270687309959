import React from "react";
import { Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddMore = ({ text }) => {
  return (
    <IconButton display="flex" alignItems="center" >
      <AddIcon sx={{ color: "#3FA268" }} />
      <Typography
        sx={{
          marginLeft: "5px",
          marginTop:"2px",
          color: "#3FA268",
          textAlign: "center",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "24px",
        }}
      >
        {text}
      </Typography>
    </IconButton>
  );
};

export default AddMore;
