import React from "react";
import {
  List,
  ListItemButton,
  ListItemText,
  ListItem,
  Box,
  Drawer,
} from "@mui/material";
import { useNavigate } from "react-router";

const MobileDrawer = ({ drawerOpen, setDrawerOpen, pages, anchor }) => {
  const navigate = useNavigate();
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{
        width: "auto",
        backgroundColor: "black",
        fontFamily: "Manrope",
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {pages.map((text, index) => (
          <React.Fragment key={text}>
            <ListItem
              disablePadding
              sx={{ borderBottom: "1px solid grey", textAlign: "center" }}
            >
              <ListItemButton onClick={()=>{
                navigate(`/${text.toLowerCase()}`)
              }}>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
  return (
    <div style={{backgroundColor: "black"}}>
      <Drawer anchor={anchor} open={drawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </div>
  );
};

export default MobileDrawer;
