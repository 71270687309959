import { Box } from "@mui/material";
import logo from "../assets/new_logo.png";
import MenuIcon from "@mui/icons-material/Menu";

const MobNav = () => {
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px",
        }}
      > 
        <img src={logo} alt="logo" />
        <MenuIcon
          sx={{
            color: "white",
            fontSize: "25px",
            cursor: "pointer",
          }}
        />
      </Box>
    </Box>
  );
};

export default MobNav;
