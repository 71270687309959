import React from "react";
import { Box } from "@mui/material";
import Home from "./components/Home";
import Footer from "../../components/Footer";
import FeaturedServices from "./components/FeaturedServices";

const LandingPage = () => {
  return (
    <Box sx={{overflowY:"scroll", overflowX:"hidden"}} height="100vh">
      <Home />
      <FeaturedServices />
      <Footer/>
    </Box>
  );
};

export default LandingPage;
