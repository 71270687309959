import React from "react";
import {
  Box,
  Grid,
  Typography,
  Avatar,
} from "@mui/material";
import { ProfilePics } from "../resources/ProfilePics";

const Team = () => {
  const Details = ({ text, size, color }) => {
    return (
      <Typography
        sx={{
          color: color,
          fontFamily: "Poppins",
          fontSize: size,
          fontWeight: "500",
          textAlign: "center",
        }}
      >
        {text}
      </Typography>
    );
  };
  const Person = ({ image, name, college, position }) => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        // padding="16px"
        // border="1px solid #ccc"
        // borderRadius="8px"
        // backgroundColor="#71797E"
      >
        <Avatar src={image} sx={{ width: "130px", height: "130px" }} />
        <Details text={name} size="24px" color="white" />
        <Box display="flex" justifyContent="center" alignItems="center">
          <Details text={position} size="18px" color="#2AAA8A" />
          <span style={{ margin: "0 8px", fontSize: "18px", color: "#50C878" }}>
            &bull;
          </span>
          <Details text={college} size="18px" color="white" />
        </Box>
      </Box>
    );
  };
  return (
    <div
      style={{
        backgroundColor: "black",
        width: "100vw",
        minHeight: "100vh",
        background: "black", // Replace with the path to your image
        backgroundSize: "cover",
        backgroundPosition: "center",
        // overflow: "hidden",
        // overflow: "hidden"
      }}
    >
      {/* <Navbar /> */}
      <Typography
        sx={{
          width: "100%",
          color: "white",
          fontSize: { xs: "30px", md: "50px" },
          fontFamily: "Manrope",
          fontWeight: "800",
          padding: "10px 40px",
          textAlign: "center",
          marginBottom: "40px",
        }}
      >
        <span style={{ color: "#3FA268" }}>People&nbsp;</span>are everything.
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          padding: "40px 40px",

          overflowY: "hidden", // Enable vertical scrolling
          maxHeight: "calc(100vh - 150px)",
          paddingBottom: "100px",
        }}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          {ProfilePics.map((person) => (
            <Grid item xs={3} key={person.name}>
              <Person
                image={person.image}
                name={person.name}
                college={person.college}
                position={person.position}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
};

export default Team;
