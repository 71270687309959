import { Box, Card, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import bg from "../../assets/Background_pattern_decorative.png";
import bg_mb from "../../assets/Background_pattern_decorative_mb.png";
import logo from "../../assets/logo.png";
import "./ResetPassword.css";
import { CircularProgress } from "@mui/material";
import resetPasswordHelper from "../../helper/resetPasswordHelper";
import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPasswordMain = ({
  forgotPasswordClient,
  setForgotPasswordClient,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  //   const [radioValue, setRadioValue] = React.useState("researcher");
  const query = useQuery();
  const email = query.get("email");
  const userType = query.get("userType");
  const token = query.get("token");

  const [password, setPassword] = React.useState("");

  // const [data, setData] = React.useState({
  //   email: "",
  //   userType: "",
  //   token: "",
  //   password: "",
  // });
  React.useEffect(() => {
    if (email && userType && token) {
      console.log("Email, userType, token", email, userType, token);
      setForgotPasswordClient({
        ...forgotPasswordClient,
        email,
        userType,
        token,
      });
      navigate("/reset-password");
    }
    console.log("Forgot Password client", forgotPasswordClient);
  }, []);

  // setData({ ...data, email, userType, token });

  async function handleContinue() {
    if (forgotPasswordClient.email === "") {
      alert("Unable to get email");
      return;
    }
    if (forgotPasswordClient.userType === "") {
      alert("Unable to get user type");
      return;
    }
    if (forgotPasswordClient.token === "") {
      alert("Unable to get token");
      return;
    }
    if (password === "") {
      alert("Please enter your new password");
      return;
    }
    forgotPasswordClient.password = password;
    console.log("Data", forgotPasswordClient);
    try {
      setLoading(true);
      const resp = await resetPasswordHelper(forgotPasswordClient);
      console.log("Response", resp);
      alert("Password reset successfully");
      navigate("/login");
    } catch (error) {
      console.log("Error in forgotPassword frntend", error);
      alert(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img src={bg} alt="Background" className="bg-desktop" />
      <img src={bg_mb} alt="Background Mobile" className="bg-mobile" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          top: "7%",
          padding: { xs: "0 16px" },
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: "360px",
            boxShadow: "none",
            bgcolor: "transparent",
            gap: "24px",
            border: "none",
          }}
        >
          <img src={logo} alt="" />
          <Typography
            sx={{
              color: "var(--Gray-900, #101828)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "30px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "38px",
            }}
          >
            Reset Password
          </Typography>
          <Typography
            sx={{
              color: "var(--Gray-600, #475467)",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "24px",
            }}
          >
            Enter a new secure password for your account
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "6px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                color: "var(--Gray-700, #344054)",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              Password
            </Typography>
            <input
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              style={{
                borderRadius: "8px",
                border: "1px solid var(--Gray-300, #D0D5DD)",
                background: "var(--Base-White, #FFF)",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                display: "flex",
                padding: "10px 14px",
                alignItems: "center",
                gap: "8px",
                width: "90%",
              }}
              placeholder="Enter your password"
            />
          </Box>

          <button
            onClick={handleContinue}
            style={{
              display: "flex",
              padding: "7px 16px",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              width: "100%",
              borderRadius: "8px",
              border: "1px solid var(--Brand-600, #099250)",
              background: "var(--Brand-600, #099250)",
              boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            }}
          >
            {loading ? (
              <CircularProgress sx={{ color: "#FEFEFE" }} />
            ) : (
              <Typography
                sx={{
                  color: "var(--Base-White, #FFF)",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "24px",
                }}
              >
                Continue
              </Typography>
            )}
          </button>
        </Card>
      </Box>
    </Box>
  );
};

export default ResetPasswordMain;
