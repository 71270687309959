import React from "react";
import { Box, Button, Typography, IconButton, Icon } from "@mui/material";
import WestIcon from "@mui/icons-material/West";

const BackContinueButtons = ({ backFun, continueFun }) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton
        onClick={() => {
          backFun();
        }}
      >
        <WestIcon color="success" />
        <Typography
          color="#3FA268"
          sx={{
            marginLeft: "5px",
            marginTop: "1px",
            textAlign: "center",
            fontFamily: "Manrope",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "24px",
          }}
        >
          Previous Step
        </Typography>
      </IconButton>
      <Button
        sx={{
          background: "linear-gradient(90deg, #3DB46C 0%, #07B54B 100%)",
          color: "white",
          fontFamily: "Manrope",
          fontSize: "15px",
          fontWeight: "700",
          textTransform: "none",
          borderRadius: "10px",
          textAlign: "center",
          padding: "5px 10px",
          "&:hover": {
            background: "linear-gradient(90deg, #3DB46C 0%, #07B54B 100%)",
          },
        }}
        onClick={() => {
          continueFun();
        }}
      >
        Continue
      </Button>
    </Box>
  );
};

export default BackContinueButtons;
