import { Box, Typography } from "@mui/material";
import React from "react";

const UserCard = ({ userData }) => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "60px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        padding: "20px",
        gap: "10px",
        borderRadius: "8px 8px 0px 0px",
        borderBottom: "1px solid rgba(255, 255, 255, 0.2)",
        borderLeft: userData.active && "4px solid #3FA268",
      }}
    >
      <img
        src={userData.img}
        style={{
          width: "45px",
          height: "45px",
          borderRadius: "45px",
        }}
        alt="profile"
      />
      <Box
        sx={{
          display: "flex",
          color: "white",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Typography fontWeight={500} fontSize="17px" lineHeight="20px">
          {userData.name}
        </Typography>
        <Typography noWrap fontWeight={400} fontSize="11px" lineHeight="20px">
          {userData.content}
        </Typography>
        <Box display="flex" alignItems={"center"} gap="5px">
          {userData.tag && (
            <Typography
              sx={{
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontSize: "11px",
                lineHeight: "20px",
                color: userData?.tag == "Good fit" ? "#99FCAF" : "#F97B35",
              }}
            >
              {userData?.tag}
              {userData?.tag && (
                <Box
                  sx={{
                    width: "3px",
                    height: "3px",
                    background: "white",
                    borderRadius: "100%",
                  }}
                ></Box>
              )}
            </Typography>
          )}
          <Typography fontWeight={400} lineHeight="20px" fontSize={"11px"}>
            {userData?.applied}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserCard;
