import React from "react";
import { TextField } from "@mui/material";

const SignUpTextField = ({
  placeholder,
  fullWidth,
  onChangeHandler,
  slug,
}) => {
  const [value, setValue] = React.useState("");
  const handleChange = (event) => {
    setValue(event.target.value);
    onChangeHandler(slug, event.target.value);
  };
  return (
    <TextField
      id={slug}
      fullWidth={fullWidth}
      value={value}
      onChange={handleChange}
      variant="standard"
      placeholder={placeholder}
      InputProps={{
        disableUnderline: true,
        style: { color: "#FFF" },
      }}
      size="small"
      sx={{
        flexGrow: 1,
        border: "none",
        borderRadius: "10px",
        border: "1.5px solid white",
        color: "#FFF",
        padding: "5px 10px",
        width: { xs: "100%", md: "95%" },
      }}
    />
  );
};

export default SignUpTextField;
